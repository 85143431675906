import type { FC } from 'react';
import type { ApexOptions } from 'apexcharts';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
  Link
} from '@material-ui/core';
import { alpha, useTheme } from '@material-ui/core/styles';
import ArrowRightIcon from '../../../icons/ArrowRight';

const Tiles: FC<any> = (props: any) => {

  const { id } = useParams();
  const theme = useTheme();

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    colors: ['#27c6db'],
    labels: [''],
    plotOptions: {
      radialBar: {
        dataLabels: {
          value: {
            show: false
          }
        },
        hollow: {
          size: '60%'
        },
        track: {
          background: theme.palette.background.default
        }
      }
    },
    theme: {
      mode: theme.palette.mode
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3
      }}
    >
      <Grid
        container
        spacing={3}
      >
    {props.tileData.map((obj) => (
                <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    key={obj.tool}
                  >

          <Card>
            <CardContent
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >

              <Box
                sx={{
                  display: 'flex',
                  flex: 1
                }}
              >
                <Box>
                  <Typography
                    color="primary"
                    variant="h6"
                  >
                    {obj.tool.replace("snyk-repos-not-scanned", "Snyk")
                             .replace("semgrep-high-vulnerabilities", "Semgrep")
                             .replace("semgrep-repos-not-scanned", "Semgrep")
                             .replace("snyk-high-priority-issues", "Snyk")
                             .replace("asec-open-tickets", "ASEC")
                             .replace("github-branch-protections", "Github")}
                  </Typography>
                  <Typography
                    color="textPrimary"
                    sx={{ mt: 1 }}
                    variant="subtitle2"
                  >
                    {obj.rating}
                  </Typography>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
              </Box>
            </CardContent>
            <Divider />
            <CardActions>
            <Link underline="none" color="textPrimary" component={RouterLink} to={`/overview/${id}/${obj.tool}`} variant="subtitle2">
              <Button
                color="primary"
                endIcon={<ArrowRightIcon fontSize="small" />}
                variant="text"
              >

                         View


              </Button>
              </Link>
            </CardActions>
          </Card>
                </Grid>
            ))}

      </Grid>
    </Box>
  );
};

export default Tiles;
