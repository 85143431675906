import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box,
  Grid,
  Link,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Button,
  Divider,
  List}
   from '@material-ui/core';
import toast from 'react-hot-toast';
import { TeamMetric } from '../../api/models';
import { getTeamMetric } from '../../api/analyticsApi';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardView from '../../components/dashboard/overview/DashboardView'
import moment from 'moment';
import * as commonHelper from './../../components/common/helper';


const GithubBranchProtectionsTileOverview: FC<any> = (props: any) => {
  const [teamData, setTeamData] = useState(null);
  const [reposWithUnprotectedBranches, setReposWithUnprotectedBranches] = useState(null);
  const [hideListOfBranches, setHideListOfBranches] = useState(false);
  const [currentRepository, setCurrentRepository] = useState(null);
  const [headerText, setHeaderText] = useState("Repositories with unprotected main/master branches");

  const [brachIsUnProtected, setBranchIsUnprotected] = useState(false);
  const [showDismissStaleReviewsInfo, setShowDismissStaleReviewsInfo] = useState(false);
  const [showRequireCodeOwnerReviewsInfo, setShowRequireCodeOwnerReviewsInfo] = useState(false);
  const [showApprovingReviewCountInfo, setShowApprovingReviewCountInfo] = useState(false);
  const [showHasDismissalRestrictionsInfo, setShowHasDismissalRestrictionsInfo] = useState(false);
  const [showEnforceAdminsInfo, setShowEnforceAdminsInfo] = useState(false);
  const [showHasNoRestrictionsInfo, setShowHasNoRestrictionsInfo] = useState(false);
  const [showAllowForcePushesInfo, setShowAllowForcePushesInfo] = useState(false);
  const [showAllowDeletionsInfo, setShowAllowDeletionsInfo] = useState(false);


  const { id } = useParams();

  useEffect(() => {
    if (props.teamsData) { (async () => {
         try {
           let metrics: any = await getMetrics();
           
           setTeamData(metrics);
           
           setTimeout(() => {
            const reposWithUnprotectedBranches = commonHelper.getReposWithUnprotectedBranches(metrics['semgrep']);
            setReposWithUnprotectedBranches(reposWithUnprotectedBranches);
            fillProtectionStatusEmoji(reposWithUnprotectedBranches)
          }, 200);

         } catch (e) {
           console.error(e);
           toast.error('Error occurred while fetching new metrics');
         }
     })();
     }
  }, [props.teamsData, id]);


  const fillProtectionStatusEmoji = (repos: string[]) => {
    let htmlStatus: HTMLDivElement | null = null;
    let branchProtectionFlags: string[] = [];
    repos?.forEach((repo) => {
      
      htmlStatus = document.getElementById(commonHelper.cleanRepoFlags(repo) + '_status',) as HTMLDivElement | null;
      branchProtectionFlags = repo?.split("|").filter(x => x.startsWith("g"));
      htmlStatus.innerHTML = getProtectionEmojiByScore(branchProtectionFlags);
    });
  }

  const getProtectionEmojiByScore = (branchProtectionFlags: string[]) => { 

    if(branchProtectionFlags.includes(commonHelper.MAIN_BRANCH_UNPROTECTED.replace("|",""))) {
      return "&#128308";
    }

    else if(branchProtectionFlags.length >= 4) {
      return "&#128993";
    }

    else if(branchProtectionFlags.length < 4) {
      return "&#128994";
    }
  }

  const showBranchDetails = (repository: string) => {
    
    setHideListOfBranches(true);
    setCurrentRepository(commonHelper.cleanRepoFlags(repository));
    setHeaderText("Branch protections detail");
    showBranchProtectionGuidelines(repository.split("|").filter(x => x.startsWith("g")));

  }

  const showBranchProtectionGuidelines = (branchProtectionFlags: string[]) => {

    
    if(branchProtectionFlags.includes(commonHelper.MAIN_BRANCH_UNPROTECTED.replace("|",""))) {
      setBranchIsUnprotected(true);
    }

    else {

      if(branchProtectionFlags.includes(commonHelper.DISMISS_STALE_REVIEWS_FALSE.replace("|",""))) {
        setShowDismissStaleReviewsInfo(true);
      }
  
      if(branchProtectionFlags.includes(commonHelper.REQUIRE_CODE_OWNER_REVIEWS_FALSE.replace("|",""))) {
        setShowRequireCodeOwnerReviewsInfo(true);
      }
  
      if(branchProtectionFlags.includes(commonHelper.APPROVING_REVIEW_COUNT_ZERO.replace("|",""))) {
        setShowApprovingReviewCountInfo(true);
        
      }
  
      if(branchProtectionFlags.includes(commonHelper.HAS_DISMISSAL_RESTRICTIONS.replace("|",""))) {
        setShowHasDismissalRestrictionsInfo(true);
      }
  
      if(branchProtectionFlags.includes(commonHelper.ENFORCE_ADMINS_FALSE.replace("|",""))) {
        setShowEnforceAdminsInfo(true);
      }
  
      if(branchProtectionFlags.includes(commonHelper.HAS_NO_RESTRICTIONS.replace("|",""))) {
        setShowHasNoRestrictionsInfo(true);
      }
  
      if(branchProtectionFlags.includes(commonHelper.ALLOW_FORCE_PUSHES_TRUE.replace("|",""))) {
        setShowAllowForcePushesInfo(true);
      }
  
      if(branchProtectionFlags.includes(commonHelper.ALLOW_DELETIONS_TRUE.replace("|",""))) {
        setShowAllowDeletionsInfo(true);
      }
    }
  }

  const showBranchList = () => {

    setHideListOfBranches(false);
    hideAllGuidelines();
    setHeaderText("Repositories with unprotected main/master branches");
    setTimeout(() => {
      fillProtectionStatusEmoji(reposWithUnprotectedBranches)
    }, 20);
    
  }

  const hideAllGuidelines = () => {

    setBranchIsUnprotected(false);
    setShowDismissStaleReviewsInfo(false);
    setShowRequireCodeOwnerReviewsInfo(false);
    setShowApprovingReviewCountInfo(false);
    setShowHasDismissalRestrictionsInfo(false);
    setShowEnforceAdminsInfo(false);
    setShowHasNoRestrictionsInfo(false);
    setShowAllowForcePushesInfo(false);
    setShowAllowDeletionsInfo(false);

  }

  const getMetrics = async () => {
    let metrics: TeamMetric[] = [];
    let daysSubstract: number = 1;

    // Get today's metrics
    if(metrics === undefined || metrics.length === 0) {
      metrics = await getTeamMetric(id, moment().format('YYYY-MM-DD'));
    }

    while((metrics === undefined || metrics.length === 0) && daysSubstract < 7) {
      
      let parsedDate = moment().subtract(daysSubstract, 'days').format('YYYY-MM-DD')
      daysSubstract++;

      metrics = await getTeamMetric(id, parsedDate);
    }

    return metrics;
  }


  return (
    <>
    { reposWithUnprotectedBranches?.length > 0 ?
      <>
        <DashboardView team={teamData.team} page={"Github Branch Protections"}/>
        
        <Box sx={{ p: 3, paddingTop: 1, paddingLeft: 3}}>
        
          <Typography variant="h5" color="text.secondary">
            {headerText}
          </Typography>
            
            <Grid
              item
              sx={{ paddingBottom: 1, paddingTop: 1 }}
            >

              {reposWithUnprotectedBranches?.length > 0 && !hideListOfBranches ? 
              <Card sx={{ marginTop: 1 }}>
                
                <CardHeader
                  title={<Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom> Total repositories with unprotected branches: {reposWithUnprotectedBranches?.length} </Typography>}
                  subheader={<Typography variant="h6" component="div">
                  </Typography>}
                />
                <CardContent>

                <div><strong><u>Emoji status meaning</u></strong></div>
                <br></br><div style={{display: 'inline'}}>🟢</div><div style={{display: 'inline'}}> Has almost all the recommended branch protections</div>
                <br></br><div style={{display: 'inline'}}>🟡</div><div style={{display: 'inline'}}> Has some recommended branch protections</div>
                <br></br><div style={{display: 'inline'}}>🔴</div><div style={{display: 'inline'}}> Does not have any branch protection</div>
                <br></br>
                <br></br>

                { reposWithUnprotectedBranches?.map((obj) => (
                  <Typography variant="body2" color="text.secondary">
                  <br></br><div style={{display: 'inline'}} id={commonHelper.cleanRepoFlags(obj) + "_status"}></div><div style={{display: 'inline'}} id={commonHelper.cleanRepoFlags(obj)}>
                  <Link target="_blank" style={{cursor: 'pointer'}} onClick={e => showBranchDetails(obj)}> {commonHelper.cleanRepoFlags(obj)}</Link>
                  </div>
                  
                  </Typography>
                ))
                }
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                  {reposWithUnprotectedBranches?.length > 0 ?
                      <>Make sure all the protections are in place for all the main/master branches of the repositories listed here. Click the repository's link to know what are the missing protections. You can find more information about the different protections and how to set them up <Link target="_blank" href="https://oktawiki.atlassian.net/wiki/spaces/PSA/pages/2830014955">here</Link>.  </> :
                      <></>
                  }
                  </Typography>
                </CardContent>
              </Card> : <></>
              }

            { hideListOfBranches ? 
              <Card sx={{ marginTop: 1 }}>
                {
                  <Button sx={{ margin: 2}} variant="contained" onClick={showBranchList} >Back to list of repositories</Button> 
                }
                <CardHeader
                  title={<Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom> Repository: <Link target="_blank" href={"http://github.com/" + currentRepository}>{currentRepository}</Link> </Typography>}
                  subheader={<Typography variant="h6" component="div">
                  </Typography>}
                />
                <CardContent>

              <List>
              <Typography sx={{ fontSize: 17 }} color="text.primary" gutterBottom> MISSING BRANCH PROTECTIONS </Typography> 
              <Divider />

                { brachIsUnProtected || showDismissStaleReviewsInfo ?
                <List>
                  <Typography sx={{ fontSize: 16 }}  gutterBottom>
                    <br></br>
                    <strong>Dismiss stale reviews</strong>
                    <Typography sx={{ fontSize: 14 }}>
                      <br></br>
                      Stale reviews are not dismissed. This means that when a commit is added to a Pull Request, the previous approval is not revoked.
                      <br></br><br></br>
                      Should be set to <strong><i>true</i></strong> to automatically dismiss approving reviews when someone pushes a new commit. This prevents merging changes that have not been reviewed.
                    </Typography>
                  </Typography>

                  <Typography sx={{ fontSize: 14 }}  gutterBottom>
                    <br></br>
                    <strong>How to configure this protection?</strong> <br></br><br></br>

                    1. Go to <Link target="_blank" href={"https://github.com/"+ currentRepository}>github.com/{currentRepository}</Link><br></br>
                    2. Select the main/master branch<br></br>
                    3. Go to Settings<br></br>
                    4. Go to Branches<br></br>
                    5. Check "Require a pull request before merging"<br></br>
                    6. Check "Dismiss stale pull request approvals when new commits are pushed"<br></br>

                  </Typography>
                  <br></br>
                  <Divider />
                  </List>
                  
                  : <></>
                }

                { brachIsUnProtected || showRequireCodeOwnerReviewsInfo ?
                <List>
                  <Typography sx={{ fontSize: 16 }}  gutterBottom>
                    <br></br>
                    <strong>Require code owners reviews</strong>
                    <Typography sx={{ fontSize: 14 }}>
                      <br></br>
                      Pull Requests are not configured to require reviews from at least one code owner. 
                      <br></br><br></br>
                      Should be set to block merging pull requests until <Link target='_blank' href="https://docs.github.com/articles/about-code-owners/">code owners</Link> review them.
                    </Typography>
                  </Typography>

                  <Typography sx={{ fontSize: 14 }}  gutterBottom>
                    <br></br>
                    <strong>How to configure this protection?</strong> <br></br><br></br>

                    1. Go to <Link target="_blank" href={"https://github.com/"+ currentRepository}>github.com/{currentRepository}</Link><br></br>
                    2. Select the main/master branch<br></br>
                    3. Go to Settings<br></br>
                    4. Go to Branches<br></br>
                    5. Check "Require a pull request before merging"<br></br>
                    6. Check "Require review from Code Owners"<br></br>

                  </Typography>
                  <br></br>

                  <Divider />
                  </List>
                  : <></>
                }


                { brachIsUnProtected || showApprovingReviewCountInfo ?
                <List>
                  <Typography sx={{ fontSize: 16 }}  gutterBottom>
                    <br></br>
                    <strong>Required approve review count</strong>
                    <Typography sx={{ fontSize: 14 }}>
                      <br></br>
                      It specifies the number of reviewers required to approve pull requests. Should be set to at least <strong>1</strong>.
                      <br></br><br></br>
                      It might be appropriate for a project to require more than one review. This might be the case for critical projects which take in a lot of contributions.
                    </Typography>
                  </Typography>

                  <Typography sx={{ fontSize: 14 }}  gutterBottom>
                    <br></br>
                    <strong>How to configure this protection?</strong> <br></br><br></br>

                    1. Go to <Link target="_blank" href={"https://github.com/"+ currentRepository}>github.com/{currentRepository}</Link><br></br>
                    2. Select the main/master branch<br></br>
                    3. Go to Settings<br></br>
                    4. Go to Branches<br></br>
                    5. Check "Require a pull request before merging"<br></br>
                    6. Check "Require approvals"<br></br>
                    7. Set "Required number of approvals before merging" to at least <strong>1</strong><br></br>

                  </Typography>
                  <br></br>

                  <Divider />
                  </List>
                  : <></>
                }

                { brachIsUnProtected || showHasDismissalRestrictionsInfo ?
                <List>
                  <Typography sx={{ fontSize: 16 }}  gutterBottom>
                    <br></br>
                    <strong>Dismissal restrictions</strong>
                    <Typography sx={{ fontSize: 14 }}>
                      <br></br>
                      Some user/s or team/s are allowed to dismiss pull request reviews. 
                      <br></br><br></br>
                      Change the configuration so that no user or team is allowed to dismiss any pull request review.
                    </Typography>
                  </Typography>

                  <Typography sx={{ fontSize: 14 }}  gutterBottom>
                    <br></br>
                    <strong>How to configure this protection?</strong> <br></br><br></br>

                    1. Go to <Link target="_blank" href={"https://github.com/"+ currentRepository}>github.com/{currentRepository}</Link><br></br>
                    2. Select the main/master branch<br></br>
                    3. Go to Settings<br></br>
                    4. Go to Branches<br></br>
                    5. Check "Require a pull request before merging"<br></br>
                    6. Make sure "Restrict who can dismiss pull request reviews" is unchecked<br></br>

                  </Typography>
                  <br></br>

                  <Divider />
                  </List>
                  : <></>
                }

                { brachIsUnProtected || showEnforceAdminsInfo ?
                <List>
                  <Typography sx={{ fontSize: 16 }}  gutterBottom>
                    <br></br>
                    <strong>Enforce admins</strong>
                    <Typography sx={{ fontSize: 14 }}>
                      <br></br>
                      All the expected branch protections are enforced for users with write access to the repository, but not to administrators.
                      <br></br><br></br>
                      Change the settings to make sure all configured branch protections are enforced for administrators as well.
                    </Typography>
                  </Typography>

                  <Typography sx={{ fontSize: 14 }}  gutterBottom>
                    <br></br>
                    <strong>How to configure this protection?</strong> <br></br><br></br>

                    1. Go to <Link target="_blank" href={"https://github.com/"+ currentRepository}>github.com/{currentRepository}</Link><br></br>
                    2. Select the main/master branch<br></br>
                    3. Go to Settings<br></br>
                    4. Go to Branches<br></br>
                    5. Check "Do not allow bypassing the above settings"<br></br>

                  </Typography>
                  <br></br>

                  <Divider />
                  </List>
                  : <></>
                }

                { brachIsUnProtected || showHasNoRestrictionsInfo ?
                <List>
                  <Typography sx={{ fontSize: 16 }}  gutterBottom>
                    <br></br>
                    <strong>No restrictions about who can push</strong>
                    <Typography sx={{ fontSize: 14 }}>
                      <br></br>
                      There are no restrictions regarding who can push to the protected branch.
                      <br></br><br></br>
                      It should be configured so that only the expected users can push to the main/master branch. It can be done both at users and team level.
                    </Typography>
                  </Typography>

                  <Typography sx={{ fontSize: 14 }}  gutterBottom>
                    <br></br>
                    <strong>How to configure this protection?</strong> <br></br><br></br>

                    1. Go to <Link target="_blank" href={"https://github.com/"+ currentRepository}>github.com/{currentRepository}</Link><br></br>
                    2. Select the main/master branch<br></br>
                    3. Go to Settings<br></br>
                    4. Go to Branches<br></br>
                    5. Check "Restrict who can push to matching branches"<br></br>
                    6. Type in the search bar the name of the users or the teams you want to allow.<br></br>

                  </Typography>
                  <br></br>

                  <Divider />
                  </List>
                  : <></>
                }

                { brachIsUnProtected || showAllowForcePushesInfo ?
                <List>
                  <Typography sx={{ fontSize: 16 }}  gutterBottom>
                    <br></br>
                    <strong>Force pushes are allowed</strong>
                    <Typography sx={{ fontSize: 14 }}>
                      <br></br>
                      Should be set to <strong>false</strong> to not allow force pushes to the protected branch by anyone with write access to the repository.
                    </Typography>
                  </Typography>

                  <Typography sx={{ fontSize: 14 }}  gutterBottom>
                    <br></br>
                    <strong>How to configure this protection?</strong> <br></br><br></br>

                    1. Go to <Link target="_blank" href={"https://github.com/"+ currentRepository}>github.com/{currentRepository}</Link><br></br>
                    2. Select the main/master branch<br></br>
                    3. Go to Settings<br></br>
                    4. Go to Branches<br></br>
                    5. Make sure "Allow force pushes" is unchecked<br></br>

                  </Typography>
                  <br></br>

                  <Divider />
                  </List>
                  : <></>
                }

                { brachIsUnProtected || showAllowDeletionsInfo ?
                <List>
                  <Typography sx={{ fontSize: 16 }}  gutterBottom>
                    <br></br>
                    <strong>Deletions are allowed</strong>
                    <Typography sx={{ fontSize: 14 }}>
                      <br></br>
                      Any user with write access to the repository can delete the main/master branch.
                      <br></br><br></br>
                      Make sure the configuration is changed to deny the deletion of the protected branch by anyone with write access to the repository.
                    </Typography>
                  </Typography>

                  <Typography sx={{ fontSize: 14 }}  gutterBottom>
                    <br></br>
                    <strong>How to configure this protection?</strong> <br></br><br></br>

                    1. Go to <Link target="_blank" href={"https://github.com/"+ currentRepository}>github.com/{currentRepository}</Link><br></br>
                    2. Select the main/master branch<br></br>
                    3. Go to Settings<br></br>
                    4. Go to Branches<br></br>
                    5. Make sure "Allow deletions" is unchecked<br></br>

                  </Typography>
                  <br></br>

                  <Divider />
                  </List>
                  : <></>
                }    

                </List> 

                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                  {reposWithUnprotectedBranches?.length > 0 ?
                      <>Make sure all the protections are in place for all the main/master branches of the repositories listed here. Click the repository's link to know what are the missing protections. You can find more information about the different protections and how to set them up <Link target="_blank" href="https://oktawiki.atlassian.net/wiki/spaces/PSA/pages/2830014955">here</Link>.  </> :
                      <></>
                  }
                  </Typography>
                </CardContent>
              </Card> : <></>
              }   
              

            </Grid>

        </Box>

      </>
        :
        <LoadingScreen />

    }

    </>
  );
};

export default GithubBranchProtectionsTileOverview;
