import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Box,
  Grid,
  Link,
  Typography,
  Card,
  CardHeader,
  CardContent} from '@material-ui/core';
import toast from 'react-hot-toast';
import { TeamMetric } from '../../api/models';
import { getTeamMetric } from '../../api/analyticsApi';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardView from '../../components/dashboard/overview/DashboardView'
import moment from 'moment';
import * as commonHelper from './../../components/common/helper';

const getReposByFlag = (semgrepData: any, flag: string) => {
  let repos = [];

  semgrepData.list_private_and_internal_repos_without_SemGrep.forEach(repo => {
    if(repo.includes(flag)) {
      repos.push(repo);
    }
  });

  semgrepData.list_private_and_internal_repos_with_SemGrep.forEach(repo => {
    if(repo.includes(flag)) {
      repos.push(repo);
    }
  });

  semgrepData.list_public_repos_without_SemGrep.forEach(repo => {
    if(repo.includes(flag)) {
      repos.push(repo);
    }
  });

  semgrepData.list_public_repos_with_SemGrep.forEach(repo => {
    if(repo.includes(flag)) {
      repos.push(repo);
    }
  });

  return repos;

}

const SnykIntegrationTileOverview: FC<any> = (props: any) => {
  const [teamData, setTeamData] = useState(null);
  const [snykData, setSnykData] = useState(null);
  const { id } = useParams();
  const [reposScanned, setReposScanned] = useState([]);
  const [reposPending, setReposPending] = useState([]);
  const [reposExcluded, setReposExcluded] = useState([]);


  useEffect(() => {
    if (props.teamsData) {
    (async () => {
         try {

           let metrics: TeamMetric[] = [];
           let daysSubstract: number = 1;

           // Get today's metrics
          if(metrics === undefined || metrics.length === 0) {
            metrics = await getTeamMetric(id, moment().format('YYYY-MM-DD'));
          }

          while((metrics === undefined || metrics.length === 0) && daysSubstract < 7) {
            
            let parsedDate = moment().subtract(daysSubstract, 'days').format('YYYY-MM-DD')
            daysSubstract++;

            metrics = await getTeamMetric(id, parsedDate);
          }

           setTeamData(metrics);
           setSnykData(metrics['snyk']);
           setReposScanned(getReposByFlag(metrics['semgrep'], commonHelper.SCANNED_REPO_FLAG));
           setReposPending(getReposByFlag(metrics['semgrep'], commonHelper.PENDING_TO_BE_SCANNED_REPO_FLAG));
           setReposExcluded(getReposByFlag(metrics['semgrep'], commonHelper.EXCLUDED_REPO_FLAG));
                  
         } catch (e) {
           console.error(e);
           toast.error('Error occurred while fetching new metrics');
         }
     })();
     }
  }, [props.teamsData, id]);


  return (
    <>

{
  reposScanned?.length > 0 || reposPending?.length > 0 || reposExcluded?.length > 0  ? 
  <>
        <DashboardView team={teamData.team} page={"Snyk Integration"}/>
        <Box sx={{ p: 3, paddingTop: 1, paddingLeft: 3}}>

        { snykData?.orgURL ?        
          <>
          <Card>
            <CardHeader
              title={<Typography variant="h5" color="text.secondary">
              Snyk Organisation:  <Link target='_blank' href={snykData?.orgURL}>  {snykData?.orgName}</Link>
            </Typography>}
            />
          </Card>
          </>
          : <></>
        }
          
          { snykData?.collectionId ?
            <>
            <br></br>
            <Card>
              <CardHeader
                title={<Typography variant="h5" color="text.secondary">
                Collection:  <Link target='_blank' href={snykData?.orgURL + "/projects/collections/" + snykData?.collectionId}>  {snykData?.collectionName}</Link>
              </Typography>}
              />
            </Card>
            </>
            : <></>
          }

            
            <Grid
              item
              sx={{ paddingBottom: 1, paddingTop: 1 }}
            >
            
               {/* Repos pending by Snyk */}

              {reposPending?.length > 0 ?

               <Card sx={{ marginTop: 2 }}>
                <CardHeader
                  title={<Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom> Repositories pending to be scanned by Snyk </Typography>}
                  subheader={<Typography variant="h6" component="div">

                        <>{reposPending?.length === 1 ? reposPending?.length + " repository" : reposPending?.length + " repositories" }</> 
                      
                  </Typography>}
                />
                <CardContent>

                { reposPending?.map((repo) => (
                  <Typography variant="body2" color="text.secondary">
                  <Link target="_blank" href={"https://github.com/"+ commonHelper.cleanRepoFlags(repo)}>{commonHelper.cleanRepoFlags(repo)}</Link>
                  </Typography>
                ))
                }
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                  {reposPending?.length > 0 ?
                      <>Make sure all those repositories are scanned with Snyk to detect potential vulnerabilities in the open source components. You can read information about how to scan your repositories with Snyk <Link href="https://oktawiki.atlassian.net/wiki/spaces/PSA/pages/2545713875/Snyk#Adding-new-repositories-to-Snyk">here</Link>.  </> :
                      <></>
                  }
                  </Typography>
                </CardContent>
              </Card> : <></>
            
            }
            

            {/* Repos scanned by Snyk */}

            { reposScanned?.length > 0 ?

              <Card sx={{ marginTop: 2 }}>
                <CardHeader
                  title={<Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom> Repositories scanned by Snyk </Typography>}
                  subheader={<Typography variant="h6" component="div">

                      <>{reposScanned?.length === 1 ? reposScanned?.length + " repository" : reposScanned?.length + " repositories" }</> 
                      
                  </Typography>}
                />
                <CardContent>

                { reposScanned?.map((repo) => (
                  <Typography variant="body2" color="text.secondary">
                  <Link target="_blank" href={"https://github.com/"+ commonHelper.cleanRepoFlags(repo)}>{commonHelper.cleanRepoFlags(repo)}</Link>
                  </Typography>
                ))
                }
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                  {reposScanned?.length > 0 ?
                      <>Keep up the great work!  </> :
                      <></>
                  }
                  </Typography>
                </CardContent>
              </Card> : <></>
            }   
              

              {/* Repos excluded to be scanned by Snyk */}
              { reposExcluded?.length > 0 ?
              <Card sx={{ marginTop: 2 }}>
                <CardHeader
                  title={<Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom> Repositories excluded to be scanned by Snyk </Typography>}
                  subheader={<Typography variant="h6" component="div">

                      <>{reposExcluded?.length === 1 ? reposExcluded?.length + " repository" : reposExcluded?.length + " repositories" }</> 
                      
                  </Typography>}
                />
                <CardContent>

                { reposExcluded?.map((repo) => (
                  <Typography variant="body2" color="text.secondary">
                  <Link target="_blank" href={"https://github.com/"+ commonHelper.cleanRepoFlags(repo)}>{commonHelper.cleanRepoFlags(repo)}</Link>
                  </Typography>
                ))
                }
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                  {reposExcluded?.length > 0 ?
                      <>These repositories do not have any manifest file (like package.json), and therefore they cannot be scanned by Snyk. All the repos listed here can be excluded from Snyk scans.  </> :
                      <></>
                  }
                  </Typography>
                </CardContent>
              </Card> : <></>
            }   

            </Grid>
        </Box>
      </>
        :
        <LoadingScreen />

  }

  </> 

  );
};

export default SnykIntegrationTileOverview;
