import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box,
  Grid,
  Link,
  Typography,
  Card,
  CardHeader,
  CardContent}
   from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import { TeamMetric } from '../../api/models';
import { getTeamMetric } from '../../api/analyticsApi';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardView from '../../components/dashboard/overview/DashboardView'
import moment from 'moment';


const ASECTileOverview: FC<any> = (props: any) => {
  const [teamData, setTeamData] = useState(null);
  const [toolData, setToolData] = useState(null);
  const { id } = useParams();


  useEffect(() => {
    if (props.teamsData) {
    (async () => {
         try {
           let result: TeamMetric[] = [];
           let daysSubstract: number = 1;

           // Get today's metrics
          if(result === undefined || result.length === 0) {
            result = await getTeamMetric(id, moment().format('YYYY-MM-DD'));
          }

          while((result === undefined || result.length === 0) && daysSubstract < 7) {
            
            let parsedDate = moment().subtract(daysSubstract, 'days').format('YYYY-MM-DD')
            daysSubstract++;

            result = await getTeamMetric(id, parsedDate);
          }

           setTeamData(result);
           setToolData(result['asec']);
         } catch (e) {
           console.error(e);
           toast.error('Error occurred while fetching new metrics');
         }
     })();
     }
  }, [props.teamsData, id]);


  return (
    <>
    { toolData ?
      <>
        <DashboardView team={teamData.team} page={"ASEC"}/>
        <Box sx={{ p: 3, paddingTop: 1, paddingLeft: 3}}>
          <Typography variant="h6" color="text.secondary">
                    ASEC Recommendations:
          </Typography>
          { toolData.projects.map((obj, idx) => (
            
            <Grid
              item
              sx={{ paddingBottom: 1, paddingTop: 1 }}
              key={idx}
            >
              <Card>
                <CardHeader
                  title={<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom> Recommendation </Typography>}
                  subheader={<Typography variant="h6" component="div">

                      {obj.openItems === 0 ?
                      <>There are no open items in this category for <span style={{color: '#ba68c8'}}>{obj.epicName}</span>. Please keep in mind that items might be added in the future.</> :

                      <>You have {obj.openItems} open items on this category for <span style={{color: '#ba68c8'}}>{obj.epicName}</span>. Please implement the suggested recommendations, or accept the risk for each item.</> }

                  </Typography>}
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    Look at the {obj.openItems === 0 ? '' : 'list of current outstanding mitigations from the'} security assessment <Link target='_blank' href={obj.threatModelUrl||obj.checklistUrl}>here</Link>.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

          ))
          }

        </Box>
      </>
        :
        <LoadingScreen />

    }

    </>
  );
};

export default ASECTileOverview;
