import axios, { AxiosRequestConfig } from 'axios';
import { getTokenSilently } from 'src/providers/StaticAuth0Provider';
import moment from 'moment';

interface Auth0AxiosRequestConfig extends AxiosRequestConfig {
  scope: string
}

const setHeaders = async (options: Auth0AxiosRequestConfig) => {
  options.headers = {};
  options.headers['Authorization'] = `Bearer ${await getTokenSilently({
    authorizationParams: {
      audience: process.env.REACT_APP_AUDIENCE,
      scope: options.scope,
    },
  })}`;
  return options;
}

const getAllTeams = async (date?: string) => {
  const parsedDate = moment(date, 'YYYY-MM-DD').isValid() ? date : moment().subtract(1, 'days').format('YYYY-MM-DD')
  let options: Auth0AxiosRequestConfig = await setHeaders({
    method: 'get',
    url: `${process.env.REACT_APP_SCORECARD_API_ENDPOINT}/teams?date=${parsedDate}`,
    scope: 'read:metrics'
  });
  return axios(options)
  .then(res => {
    
    // Remove 'Test - Do not use' team
    res.data = res.data.filter(x => x.team.name != "Test - Do not use");

    return res.data;
  })
  .catch(err => {
    //Purposely left blank for a better error handling experience
  })

}

const getTeamMetric = async (teamID, date?: string) => {
  const parsedDate = moment(date, 'YYYY-MM-DD').isValid() ? date : moment().subtract(1, 'days').format('YYYY-MM-DD')
  let options: Auth0AxiosRequestConfig = await setHeaders({
    method: 'get',
    url: `${process.env.REACT_APP_SCORECARD_API_ENDPOINT}/teams/${teamID}?date=${parsedDate}`,
    scope: 'read:metrics'
  });

  return axios(options)
    .then((res) => {
      return res.data;
    })
    .catch(err => {
      //Purposely left blank for a better error handling experience
    })
}

const getMaturityScoreOverTime = async (teamID, date?: string) => {
  let options: Auth0AxiosRequestConfig = await setHeaders({
    method: 'get',
    url: `${process.env.REACT_APP_SCORECARD_API_ENDPOINT}/teams/score/${teamID}`,
    scope: 'read:metrics'
  });

  return axios(options)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

export {
  getAllTeams,
  getTeamMetric,
  getMaturityScoreOverTime as getRiskScoreOverTime
};
