import type { FC } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, CssBaseline, AppBar,
  AppBarActions,
  AppBarContent,
  AppBarIconButton,
  Text,
  Image } from "@a0/quantum-product";
import { experimentalStyled } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Logo from '../../components/Logo.png'

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)`
  color: #ffffff;
  background-color: #5664d2;
`;

const DashboardAppBarIconButton = experimentalStyled(AppBarIconButton)`
  :hover {
    background-color: #505bb3
  }
`;

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <ThemeProvider>
    <CssBaseline />
    <DashboardNavbarRoot {...other}>
      <AppBarContent>

        <AppBarActions>
          <DashboardAppBarIconButton href="/">
          <Image
            src={Logo}
            alt="Autho Compass Logo"
            width="40px"
            height="40"/>
          </DashboardAppBarIconButton>
          <Text>
            Auth0 Security Compass
          </Text>
        </AppBarActions>
        <AppBarActions flex={1} justifyContent="flex-end">
       <NotificationsPopover />
        <AccountPopover />
        </AppBarActions>
      </AppBarContent>
    </DashboardNavbarRoot>
    </ThemeProvider>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
