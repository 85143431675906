import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Box,
  Grid,
  Link,
  Typography,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem} from '@material-ui/core';
import toast from 'react-hot-toast';
import { TeamMetric } from '../../api/models';
import { getTeamMetric } from '../../api/analyticsApi';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardView from '../../components/dashboard/overview/DashboardView'
import moment from 'moment';

const SnykTileOverview: FC<any> = (props: any) => {
  const [teamData, setTeamData] = useState(null);
  const [toolData, setToolData] = useState(null);
  const { id } = useParams();


  useEffect(() => {
    if (props.teamsData) {
    (async () => {
         try {
           let metrics: TeamMetric[] = [];
           let daysSubstract: number = 1;

           // Get today's metrics
          if(metrics === undefined || metrics.length === 0) {
            metrics = await getTeamMetric(id, moment().format('YYYY-MM-DD'));
          }

          while((metrics === undefined || metrics.length === 0) && daysSubstract < 7) {
            
            let parsedDate = moment().subtract(daysSubstract, 'days').format('YYYY-MM-DD')
            daysSubstract++;

            metrics = await getTeamMetric(id, parsedDate);
          }

           setTeamData(metrics);
           setToolData(metrics['snyk']);

           
         } catch (e) {
           console.error(e);
           toast.error('Error occurred while fetching new metrics');
         }
     })();
     }
  }, [props.teamsData, id]);


  return (
    <>
    { toolData ?
      <>
        <DashboardView team={teamData.team} page={"Snyk"}/>
        <Box sx={{ p: 3, paddingTop: 1, paddingLeft: 3}}>

                  { toolData?.orgURL ?
                    <>
                    <Card>
                      <CardHeader
                        title={<Typography variant="h5" color="text.secondary">
                        Snyk Organisation:  <Link target='_blank' href={toolData.orgURL}>  {toolData.orgName}</Link>
                      </Typography>}
                      />
                    </Card>
                    </>
                    : <></>
                  }

                  { toolData?.collectionId ?
                    <>
                    <br></br>
                    <Card>
                      <CardHeader
                        title={<Typography variant="h5" color="text.secondary">
                        Collection:  <Link target='_blank' href={toolData.orgURL + "/projects/collections/" + toolData.collectionId}>  {toolData.collectionName}</Link>
                      </Typography>}
                      />
                    </Card> 
                    </>
                    : <></>
                  }


                      <Typography style={{paddingTop: '40px'}} variant="h6" color="text.secondary">
                      {(toolData.issues.highPriorityScoreFindings.length < 1 ) ? 'Nice! No issues with a priority score over the allowed threshold were found. 👍' : <>Findings with a 770 <Link target='_blank' href="https://docs.snyk.io/manage-issues/issue-management/priority-score">Priority Score</Link> or higher: {toolData.issues.highPriorityScoreFindings.length}</> }
                      </Typography>

                      { toolData.issues.highPriorityScoreFindings?.map((obj, i) => (
                        <Grid
                              item
                              sx={{ paddingBottom: 1, paddingTop: 1 }}
                              key={i}
                        >
                                <Card>
                                  <CardHeader
                                    title={<Typography sx={{ fontSize: 16 }} color="text.main" gutterBottom>  <Link target='_blank' href={obj.url}>{obj.title}</Link> in "{obj.package}"
                                    </Typography>}

                                    subheader={<Typography  sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Priority Score: {obj.priorityScore}
                                    </Typography>}
                                    />

                                    <CardContent>
                                      <Typography variant="subtitle1" component="div">
                                       The projects below might be affected by a vulnerability brought in by a dependency. Please fix these vulnerabilities in order to mark this recommendation as completed.
                                      </Typography>

                                      <Typography variant="subtitle1" component="div">
                                        <List>
                                        { obj.projects?.map((project, i) => (
                                          <ListItem  key={i}>
                                            <Link target='_blank'  href={project.url}> {project.name}</Link>
                                          </ListItem>
                                          ))}
                                        </List>

                                      </Typography>
                                  </CardContent>
                                </Card>
                          </Grid>
                        ))}



        </Box>
      </>
        :
        <LoadingScreen />

    }

  </> 

  );
};

export default SnykTileOverview;
