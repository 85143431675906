import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Container,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import {
  TeamMetric
} from '../../api/models';

import { useTheme } from '@material-ui/core/styles';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';

import LoadingScreen from 'src/components/LoadingScreen';

const OverviewDashboard: FC<any> = (props: any) => {

  const theme = useTheme();
  const { settings } = useSettings();

  const getBackgroundColor = (obj: TeamMetric) => {
    if (obj.risk < 5) {
      //red
      return theme.palette.error.light
    } else if (obj.risk >= 5 && obj.risk < 6){
      //orange
      return theme.palette.warning.light
      
    } else if (obj.risk >= 6 && obj.risk < 8){
      //yellow
      return ` #F5E636`
    } else if (obj.risk >= 8){
      //green
      return theme.palette.success.light
    } else {
      return theme.palette.error.light
    }
  }

  const getTextColor = (obj: TeamMetric) => {
    if (obj.risk < 5) {
      //red
      return theme.palette.error.dark
    } else if (obj.risk >= 5 && obj.risk < 6){
      //orange
      return theme.palette.warning.dark
    } else if (obj.risk >= 6 && obj.risk < 8){
      //yellow
      return `#aba125`
    } else if (obj.risk >= 8){
      //green
      return theme.palette.success.dark
    } else {
      return theme.palette.error.dark
    }
  }

  return (
    <>
      <Helmet>
        <title>Security Compass | Overview</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Maturity Security Posture Overview
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboards
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Overview
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          {props.teamsData ?
            <Box
              sx={{
                py: 3
              }}
            >
              <Grid
                container
                spacing={3}
              >
                {props.teamsData.map((obj) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={3}
                    key={obj.teamID}
                  >
                    <Link
                      underline='none'
                      to={`/overview/${obj.teamID}`}
                      component={RouterLink}
                    >
                      <Card
                        sx={{
                          ':hover': {
                            boxShadow: 10, },
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          minHeight: '100px',
                          textAlign: 'left'
                        }}
                      >
                      <CardActionArea>
                        <Card
                          sx={{
                            minHeight: '80px',
                            backgroundColor: getBackgroundColor(obj),
                            textAlign: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                            <Typography
                              variant="h5"
                              sx={{
                                color: getTextColor(obj),
                                }}

                              >
                              {obj.risk}
                            </Typography>
                          </Card>
                        <CardContent>
                          <Typography gutterBottom variant="body1" component="div">
                            {obj.teamName}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      </Card>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
             : <LoadingScreen></LoadingScreen>
          }
        </Container>
      </Box>
    </>
  );
};

export default OverviewDashboard;
