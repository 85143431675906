import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box,
  Grid,
  Link,
  Typography,
  Card,
  CardHeader,
  CardContent}
   from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import { TeamMetric } from '../../api/models';
import { getTeamMetric } from '../../api/analyticsApi';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardView from '../../components/dashboard/overview/DashboardView'
import moment from 'moment';


const SemgrepHighVulnerabilitiesTileOverview: FC<any> = (props: any) => {
  const [teamData, setTeamData] = useState(null);
  const [semgrepData, setSemgrepData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (props.teamsData) {
    (async () => {
         try {
           let metrics: TeamMetric[] = [];
           let daysSubstract: number = 1;

           // Get today's metrics
          if(metrics === undefined || metrics.length === 0) {
            metrics = await getTeamMetric(id, moment().format('YYYY-MM-DD'));
          }

          while((metrics === undefined || metrics.length === 0) && daysSubstract < 7) {
            
            let parsedDate = moment().subtract(daysSubstract, 'days').format('YYYY-MM-DD')
            daysSubstract++;

            metrics = await getTeamMetric(id, parsedDate);
          }

           setTeamData(metrics);
           setSemgrepData(metrics['semgrep']);
         } catch (e) {
           console.error(e);
           toast.error('Error occurred while fetching new metrics');
         }
     })();
     }
  }, [props.teamsData, id]);


  return (
    <>
    { semgrepData ?
      <>
        <DashboardView team={teamData.team} page={"Semgrep High Vulnerabilities"}/>
        <Box sx={{ p: 3, paddingTop: 1, paddingLeft: 3}}>
            
            <Grid
              item
              sx={{ paddingBottom: 1, paddingTop: 1 }}
            >
              {/* Private/Internal repositories without Semgrep */}
              {semgrepData.list_repos_with_high_vulnerabililties?.length > 0 && 
              semgrepData.high_vulnerabilities > 0 ? 
              <Card sx={{ marginTop: 2 }}>
                <CardHeader
                  title={<Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom> Total high vulnerabilities: {semgrepData.high_vulnerabilities} </Typography>}
                  subheader={<Typography variant="h6" component="div">

                    <>Repositories</>
                      
                  </Typography>}
                />
                <CardContent>

                { semgrepData.list_repos_with_high_vulnerabililties?.map((obj) => (
                  <Typography variant="body2" color="text.secondary">
                  <Link target="_blank" href={"https://semgrep.dev/orgs/-/findings?repo="+ obj + "&severity=2"}>{obj}</Link>
                  </Typography>
                ))
                }
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                  {semgrepData.list_repos_with_high_vulnerabililties?.length > 0 ?
                      <>Make sure you review the high vulnerabilities of all the repositories listed here. Click on the repository links to access the Semgrep page that will list the high vulnerabilities of each repository. You can find more information about the triage process <Link target="_blank" href="https://oktawiki.atlassian.net/wiki/spaces/PSA/pages/2775842875/SAST+SCA+Triage#SAST-Finding-Triage-Process">here</Link>.  </> :
                      <></>
                  }
                  </Typography>
                </CardContent>
              </Card> : <></>
              }

            </Grid>

        </Box>
      </>
        :
        <LoadingScreen />

    }

    </>
  );
};

export default SemgrepHighVulnerabilitiesTileOverview;
