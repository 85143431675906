
export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  authorizationParams: {
    audience: process.env.REACT_APP_AUDIENCE,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    scope: "openid profile email read:metrics"
  },
  issuer: process.env.REACT_APP_ISSUER,
};

// domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
// clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
// audience={process.env.NEXT_PUBLIC_AUDIENCE!}
// issuer={process.env.NEXT_PUBLIC_ISSUER!}
// redirectUri={process.env.NEXT_PUBLIC_REDIRECT_URI!}
// onRedirectCallback={onRedirectCallback}
// scope="read:metrics"
