import type { FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import useSettings from '../../../hooks/useSettings';
import ChevronRightIcon from '../../../icons/ChevronRight';
import { useTheme } from '@material-ui/core/styles';

const ToolSpecificDashboard: FC<any> = (props: any) => {
  const theme = useTheme();
  const { settings } = useSettings();


  return (
    <>
     <Toaster position="top-center" />
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 8,
        }}
      >

          <Container maxWidth={settings.compact ? 'xl' : false}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography color="textPrimary" variant="h5">
                {props.team.name} - {props.page} Data
                </Typography>

                <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
                  <Link color="textPrimary" component={RouterLink} to="/" variant="subtitle2">
                    Dashboards
                  </Link>
                  <Link color="textPrimary" component={RouterLink} to="/overview" variant="subtitle2">
                    Overview
                  </Link>
                  <Link color="textPrimary" component={RouterLink} to={`/overview/${props.team.id}`} variant="subtitle2">
                    {props.team.name}
                    </Link>
                  <Typography color="textSecondary" variant="subtitle2">
                    {props.page}
                  </Typography>

                </Breadcrumbs>
              </Grid>
            </Grid>

          </Container>

      </Box>
    </>
  );
};

export default ToolSpecificDashboard;
