import type { FC } from 'react';
import type { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { Box, Card, CardContent, CardHeader, Divider, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Scrollbar from '../../Scrollbar';
import DotsHorizontalIcon from '../../../icons/DotsHorizontal';


const RiskChart: FC<any> = (props: any) => {
  let {riskOverTime} = props;

  const theme = useTheme();

  const risk = riskOverTime.map(item => {
    const container = {
      y: null,
      x: null
    };

    container.y = item.risk;
    container.x = item.date;

    return container;
})


  const data = {
    series: [
      {
        name: "Maturity Score",
        data: risk
      }
    ]
  };

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    colors: ['#00ab57'],
    dataLabels: {
      enabled: false
    },
    fill: {
      gradient: {
        opacityFrom: 0.4,
        opacityTo: 0.1,
        shadeIntensity: 1,
        stops: [0, 100],
        type: 'vertical'
      },
      type: 'gradient'
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: 'smooth'
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      min: 0,
      max: 10,
      labels: {
        // @ts-ignore
        formatter: (value) => (value > 0 ? `${value}` : value),
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary
        }
      }
    }
  };

  const chartSeries = data.series;

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3
      }}
    >
      <Card>
        <CardHeader
          action={(
            <IconButton>
              <DotsHorizontalIcon fontSize="small" />
            </IconButton>
          )}
          title="Maturity Score Over Last 60 Days"
        />
        <Divider />
        <CardContent>

            <Box
              sx={{
                height: 475,
                minWidth: 500,
                position: 'relative'
              }}
            >
              <Chart
                height="450"
                options={chartOptions}
                series={chartSeries}
                type="area"
              />
            </Box>
            
        </CardContent>
      </Card>
    </Box>
  );
};

export default RiskChart;
