import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled('svg')``;

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot
    height="52"
    version="1.1"
    viewBox="0 0 52 52"
    width="52"
    {...props}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 309.3 343.8">
      <defs>
        <style>{".cls-1{ fill: #fff; }"}</style>
        </defs>
        <g id="Layer_1" data-name="Layer 1">
          <path className ="cls-1" d="M266.31,0H154.65l34.51,107.54H300.82l-90.34,64.13L245,279.82h0c58.15-42.36,77.15-106.47,55.83-172.28ZM8.48,107.54H120.14L154.65,0H43L8.48,107.54h0c-21.33,65.81-2.32,129.92,55.83,172.28h0L98.82,171.67ZM64.31,279.82l90.34,64,90.34-64-90.34-65.06Z"/>
        </g>
      </svg>
  </LogoRoot>
);

export default Logo;
