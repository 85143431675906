import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingBar from './components/LoadingBar';
import MainLayout from './components/MainLayout';
import OverviewDashboard from './pages/dashboard/OverviewDashboard';
import TeamOverview from './pages/dashboard/TeamOverview';
import SnykTileOverview from './pages/dashboard/SnykTileOverview';
import ASECTileOverview from './pages/dashboard/ASECTileOverview';
import SemgrepTileOverview from './pages/dashboard/SemgrepTileOverview';
import SnykIntegrationTileOverview from './pages/dashboard/SnykIntegrationTileOverview';
import SemgrepHighVulnerabilitiesTileOverview from './pages/dashboard/SemgrepHighVulnerabilitiesOverview';
import GithubBranchProtectionsTileOverview from './pages/dashboard/GithubBranchProtectionsOverview';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingBar />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// Dashboard pages

const JiraDashboard = Loadable(lazy(() => import('./pages/dashboard/JiraDashboard')));
const SnykDashboard = Loadable(lazy(() => import('./pages/dashboard/SnykDashboard')));

// Pages
const FeedbackDetails = Loadable(lazy(() => import('./pages/FeedbackDetails')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes: any = (props) => [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      }
    ]
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/overview"
            replace
          />
        )
      },
      {
        path: '/overview',
        children: [
          {
            path: '/',
            element: <OverviewDashboard teamsData={props.teamsData}/>
          },
          {
            path: '/:id',
            element: <TeamOverview teamsData={props.teamsData}/>
          },
          {
            path: '/:id/snyk-high-priority-issues',
            element: <SnykTileOverview teamsData={props.teamsData}/>
          },
          {
            path: '/:id/asec-open-tickets',
            element: <ASECTileOverview teamsData={props.teamsData}/>
          },
          {
            path: '/:id/semgrep-repos-not-scanned',
            element: <SemgrepTileOverview teamsData={props.teamsData}/>
          },
          {
            path: '/:id/snyk-repos-not-scanned',
            element: <SnykIntegrationTileOverview teamsData={props.teamsData}/>
          },
          {
            path: '/:id/semgrep-high-vulnerabilities',
            element: <SemgrepHighVulnerabilitiesTileOverview teamsData={props.teamsData}/>
          },
          {
            path: '/:id/github-branch-protections',
            element: <GithubBranchProtectionsTileOverview teamsData={props.teamsData}/>
          },
        ]
      },
      {
        path: '/jira',
        element: <JiraDashboard teamsData={props.teamsData}/>
      },
      {
        path: '/snyk',
        element: <SnykDashboard teamsData={props.teamsData}/>
      },
      {
        path: '/feedback',
        element: <FeedbackDetails/>
      },
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
