import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box,
  Grid,
  Link,
  Typography,
  Card,
  CardHeader,
  CardContent}
   from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import { TeamMetric } from '../../api/models';
import { getTeamMetric } from '../../api/analyticsApi';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardView from '../../components/dashboard/overview/DashboardView'
import moment from 'moment';
import * as commonHelper from './../../components/common/helper';


const SemgrepTileOverview: FC<any> = (props: any) => {
  const [teamData, setTeamData] = useState(null);
  const [semgrepData, setSemgrepData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (props.teamsData) {
    (async () => {
         try {
           let metrics: TeamMetric[] = [];
           let daysSubstract: number = 1;

           // Get today's metrics
          if(metrics === undefined || metrics.length === 0) {
            metrics = await getTeamMetric(id, moment().format('YYYY-MM-DD'));
          }

          while((metrics === undefined || metrics.length === 0) && daysSubstract < 7) {
            
            let parsedDate = moment().subtract(daysSubstract, 'days').format('YYYY-MM-DD')
            daysSubstract++;

            metrics = await getTeamMetric(id, parsedDate);
          }

           setTeamData(metrics);
           setSemgrepData(metrics['semgrep']);
         } catch (e) {
           console.error(e);
           toast.error('Error occurred while fetching new metrics');
         }
     })();
     }
  }, [props.teamsData, id]);


  return (
    <>
    { semgrepData ?
      <>
        <DashboardView team={teamData.team} page={"Semgrep"}/>
        <Box sx={{ p: 3, paddingTop: 1, paddingLeft: 3}}>
            
            <Grid
              item
              sx={{ paddingBottom: 1, paddingTop: 1 }}
            >
              {/* Private/Internal repositories without Semgrep */}
              {semgrepData.list_private_and_internal_repos_without_SemGrep?.length > 0 || 
              semgrepData.list_private_and_internal_repos_with_SemGrep?.length > 0 ? 
              <Card sx={{ marginTop: 2 }}>
                <CardHeader
                  title={<Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom> Private/Internal repositories without Semgrep </Typography>}
                  subheader={<Typography variant="h6" component="div">

                      {semgrepData.list_private_and_internal_repos_without_SemGrep?.length === 0 ?
                      <>There are no private/internal repositories not being scanned by Semgrep  </> :

                      <>{semgrepData.list_private_and_internal_repos_without_SemGrep?.length} repositories not being scanned</>
                       }
                      
                  </Typography>}
                />
                <CardContent>

                { semgrepData.list_private_and_internal_repos_without_SemGrep?.map((obj) => (
                  <Typography variant="body2" color="text.secondary">
                  <Link href={"https://github.com/"+ commonHelper.cleanRepoFlags(obj)}>{commonHelper.cleanRepoFlags(obj)}</Link>
                  </Typography>
                ))
                }
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                  {semgrepData.list_private_and_internal_repos_without_SemGrep?.length > 0 ?
                      <>Make sure all those repositories are scanned with Semgrep to detect potential vulnerabilities in the code. You can read information about the SemGrep integration procedure <Link href="https://oktawiki.atlassian.net/wiki/spaces/PSA/pages/2545714523/Semgrep+Integration+Procedure">here</Link>.  </> :
                      <></>
                  }
                  </Typography>
                </CardContent>
              </Card> : <></>
              }

              {/* Public repositories without Semgrep */}
              {semgrepData.list_public_repos_with_SemGrep?.length > 0 || 
              semgrepData.list_public_repos_without_SemGrep?.length > 0 ? 
              <Card sx={{ marginTop: 1 }}>
                <CardHeader
                  title={<Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom> Public repositories without Semgrep </Typography>}
                  subheader={<Typography variant="h6" component="div">

                      {semgrepData.list_public_repos_without_SemGrep?.length === 0 ?
                      <>There are no public repositories not being scanned by Semgrep  </> :
                      <>{semgrepData.list_public_repos_without_SemGrep?.length} repositories not being scanned</>
                       }
                      
                  </Typography>}
                />
                <CardContent>

                { 
                
                  semgrepData.list_public_repos_without_SemGrep?.map((repo) => (
                  <Typography variant="body2" color="text.secondary">          
                  <Link href={"https://github.com/"+ commonHelper.cleanRepoFlags(repo)}>{commonHelper.cleanRepoFlags(repo)}</Link>
                  </Typography>
                ))
                }
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                  {semgrepData.list_public_repos_without_SemGrep?.length > 0 ?
                      <>Make sure all those repositories are scanned with Semgrep to detect potential vulnerabilities in the code. You can read information about the SemGrep integration procedure <Link href="https://oktawiki.atlassian.net/wiki/spaces/PSA/pages/2545714523/Semgrep+Integration+Procedure">here</Link>.  </> :
                      <></>
                  }
                  </Typography>
                </CardContent>
              </Card> : <></>
              }

              {/* Private/Internal repositories with Semgrep */}
              {semgrepData.list_private_and_internal_repos_without_SemGrep?.length > 0 || 
              semgrepData.list_private_and_internal_repos_with_SemGrep?.length > 0 ? 
              <Card sx={{ marginTop: 2 }}>
                <CardHeader
                  title={<Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom> Private/Internal repositories with Semgrep </Typography>}
                  subheader={<Typography variant="h6" component="div">

                      {semgrepData.list_private_and_internal_repos_with_SemGrep?.length === 0 ?
                      <>There are no private/internal repositories being scanned by Semgrep  </> :

                      <>{semgrepData.list_private_and_internal_repos_with_SemGrep?.length} repositories being scanned</>
                       }
                      
                  </Typography>}
                />
                <CardContent>

                { semgrepData.list_private_and_internal_repos_with_SemGrep?.map((repo) => (
          
                  <Typography variant="body2" color="text.secondary">        
                  <Link href={"https://github.com/"+ commonHelper.cleanRepoFlags(repo)}>{commonHelper.cleanRepoFlags(repo)}</Link>
                  </Typography>
                ))
                }
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                  {semgrepData.list_private_and_internal_repos_with_SemGrep?.length > 0 ?
                      <>Keep up the great work! </> :
                      <></>
                  }
                  </Typography>
                </CardContent>
              </Card> :
              
              <Card sx={{ marginTop: 2 }}>
                <CardHeader
                  title={<Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom> </Typography>}
                  subheader={<Typography variant="h6" component="div">

                  This team does not have any private/internal repository
                      
                  </Typography>}
                />
              </Card>
              }

              {/* Public repositories with Semgrep */}
              {semgrepData.list_public_repos_with_SemGrep?.length > 0 || 
              semgrepData.list_public_repos_without_SemGrep?.length > 0 ? 
              <Card sx={{ marginTop: 1 }}>
                <CardHeader
                  title={<Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom> Public repositories with Semgrep </Typography>}
                  subheader={<Typography variant="h6" component="div">

                      {semgrepData.list_public_repos_with_SemGrep?.length === 0 ?
                      <>There are no public repositories being scanned by Semgrep  </> :
                      <>{semgrepData.list_public_repos_with_SemGrep?.length} repositories being scanned</>
                       }
                      
                  </Typography>}
                />
                <CardContent>

                { semgrepData.list_public_repos_with_SemGrep?.map((repo) => (
                  <Typography variant="body2" color="text.secondary">          
                  <Link href={"https://github.com/"+ commonHelper.cleanRepoFlags(repo)}>{commonHelper.cleanRepoFlags(repo)}</Link>
                  </Typography>
                ))
                }
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                  {semgrepData.list_public_repos_with_SemGrep?.length > 0 ?
                      <>Keep up the great work!  </> :
                      <></>
                  }
                  </Typography>
                </CardContent>
              </Card> :

              <Card sx={{ marginTop: 2 }}>
              <CardHeader
                title={<Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom> </Typography>}
                subheader={<Typography variant="h6" component="div">

                This team does not have any public repository
                </Typography>}
              />
              </Card>
            }

            </Grid>


        </Box>
      </>
        :
        <LoadingScreen />

    }

    </>
  );
};

export default SemgrepTileOverview;
