import type { FC } from 'react';
import {
  Box,
  Container,
  Typography
} from '@material-ui/core';
import Logo from './Logo';

const Footer: FC = (props) => (
  <Box
    sx={{
      backgroundColor: 'background.default',
      pb: 6,
      pt: {
        lg: 6,
        md: 6,
        xs: 6
      }
    }}
    {...props}
  >
    <Container 
      maxWidth="lg">
      <Logo />

      <Typography
        color="textSecondary"
        sx={{ mt: 1 }}
        variant="caption"
      >
        © 2021 Auth0.
      </Typography>
    </Container>
  </Box>
);

export default Footer;
