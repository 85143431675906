// @ts-nocheck

import { useEffect, useState, useMemo } from 'react';
import type { FC } from 'react';
import { useRoutes, useLocation} from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import SettingsDrawer from './components/SettingsDrawer';
import { useAuth0 } from '@auth0/auth0-react';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createCustomTheme } from './theme';
import { getAllTeams, getTeamMetric } from './api/analyticsApi';
import { TeamMetric } from './api/models';
import moment from 'moment';


const App: FC = () => {
  const { settings } = useSettings();
  const auth = useAuth0();
  const [ teamsData, setTeamsData ] = useState(null);
  let query = useQuery();
  const content = useRoutes(routes({ teamsData }));

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  useScrollReset();
  useEffect(() => {
    if (!auth.isLoading && auth.isAuthenticated) {
      (async () => {
        try {
          let result: TeamMetric[] = [];
          let teams: {}[] = [];

          // Get today's metrics
          if(result === undefined || result.length === 0) {
            result = await getAllTeams(moment().format('YYYY-MM-DD'));
          }

          let daysSubstract: number = 1;

          while((result === undefined || result.length === 0) && daysSubstract < 7) {
            
            let parsedDate = moment().subtract(daysSubstract, 'days').format('YYYY-MM-DD')
            daysSubstract++;

            result = await getAllTeams(parsedDate);
          }

          // Check if the backend process has been failing for more than three days
          if(daysSubstract > 4) {
            toast.error('These metrics are from ' + String(daysSubstract-1) + ' days ago. The process that retrieves and processes all the metrics has been failing for ' + String(daysSubstract-1) + ' days. If you see this error message, please let the Product Security team know through the Slack channel #security-compass. Thank you!');
          }

          result.map((obj) => {
              let teamName = obj.team.name;
              let teamID = obj.team.id;
              teams.push({ teamName, teamID, risk: obj.risk});
            });
            // Sort by team teamName
          teams.sort((a: any, b: any) => (a.teamName > b.teamName) ? 1 : (b.teamName > a.teamName ? -1 : 0));
          setTeamsData(teams);

        } catch (e) {
          console.error(e);
          toast.error('Error occurred while fetching new metrics');
        }
      })();
    }
  }, [auth.isLoading, auth.isAuthenticated]);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      <SettingsDrawer />
      {!auth.isLoading ? content : null}
    </ThemeProvider>
  );
};

export default App;
