import { useState, useEffect } from 'react';
import type { FC } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Alert,
  Link
} from '@material-ui/core';
import Label from '../../Label';
import Tiles from './ToolTiles'
import RiskChart from './RiskChart'
import * as commonHelper from './../../common/helper';
import { common } from '@material-ui/core/colors';

const TeamOverviewRecommendation: FC<any> = (props: any) => {
  const { recommendations, riskOverTime } = props;
  const [isOpen, setAlertOpen] = useState<boolean>(true);
  const [tileData, setTileData] = useState([]);
  const [redAlertSituation, setRedAlertSituation] = useState<boolean>(true);



  useEffect(() => {
    const arr = [];
    if (props.teamData) {
      
      showTeamScoreInformation();
      fillStatusEmoji(props.teamData.riskFactors)

      checkRedAlertMetrics(props.teamData.redAlerts);

      if(redAlertSituation === true) {
        setTimeout(() => {
          checkRedAlertMetrics(props.teamData.redAlerts);
          fillRedAlertMetricsInfo(props);
        }, 200);
      }

      addASECTiles(arr);
      addSnykTiles(arr);
      addSemgrepTiles(arr);
      addGithubTiles(arr);

     setTileData(arr);
    }  
  }, [props.teamData]);

  const showTeamScoreInformation = () => {
    const openSECTickets = document.getElementById('openSECTickets',) as HTMLDivElement | null;
    const openSECTicketsSLA = document.getElementById('openSECTicketsSLA',) as HTMLDivElement | null;
    const isUsingSemgrep = document.getElementById('isUsingSemgrep',) as HTMLDivElement | null;
    const highVulnsSemgrep = document.getElementById('highVulnsSemgrep',) as HTMLDivElement | null;
    const isUsingSnyk = document.getElementById('isUsingSnyk',) as HTMLDivElement | null;
    const highVulnsInSnyk = document.getElementById('highVulnsInSnyk',) as HTMLDivElement | null;
    const hasSecChamp = document.getElementById('hasSecChamp',) as HTMLDivElement | null;
    const asecTickets = document.getElementById('asecTickets',) as HTMLDivElement | null;
    const branchProtections = document.getElementById('branchProtections',) as HTMLDivElement | null;
    const totalScore = document.getElementById('totalScore',) as HTMLDivElement | null;
    const moreInfo = document.getElementById('moreInfo',) as HTMLDivElement | null;

    const maturityPoints = getMaturityPoints(props.teamData);
    const redAlertsScore = getRedAlertsScore(props.teamData);
    const scoreDescription = getScoreScription(redAlertSituation, redAlertsScore, maturityPoints);

    openSECTickets.innerHTML = " <strong>Number of open SEC tickets:</strong> " + props.teamData.riskFactors?.jira_open_issues + " out of " + ""+commonHelper.MAXIMUM_SCORES_METRICS.OPEN_SEC_TICKETS + " maturity points"
    openSECTicketsSLA.innerHTML = " <strong>Number of open SEC tickets with past SLA:</strong> " + props.teamData.riskFactors?.jira_open_issues_breached_sla + " out of " + ""+commonHelper.MAXIMUM_SCORES_METRICS.OPEN_SEC_TICKETS_PAST_SLA + " maturity points"
    isUsingSemgrep.innerHTML = " <strong>The team is using SemGrep in their repositories:</strong> " + props.teamData.riskFactors?.is_using_semgrep_on_some_repos + " out of " + ""+commonHelper.MAXIMUM_SCORES_METRICS.USING_SEMGREP + " maturity points"
    highVulnsSemgrep.innerHTML = " <strong>The team has no high vulnerabilities not triaged/fixed in SemGrep:</strong> " + props.teamData.riskFactors?.high_vulnerabilities_in_semgrep + " out of " + ""+commonHelper.MAXIMUM_SCORES_METRICS.NO_HIGH_VULN_SEMGREP + " maturity points"
    isUsingSnyk.innerHTML = " <strong>The team is using Snyk:</strong> " + props.teamData.riskFactors?.snyk_org_not_found + " out of " + ""+commonHelper.MAXIMUM_SCORES_METRICS.USING_SNYK + " maturity points"
    highVulnsInSnyk.innerHTML = " <strong>The team has no high priority issues in Snyk:</strong> " + props.teamData.riskFactors?.snyk_max_priority_score_reached + " out of " + ""+commonHelper.MAXIMUM_SCORES_METRICS.NO_HIGH_PRIORITY_ISSUES_SNYK + " maturity points"
    hasSecChamp.innerHTML = " <strong>The team has at least one Security Champion:</strong> " + props.teamData.riskFactors?.other_lack_of_sec_champs + " out of " + ""+commonHelper.MAXIMUM_SCORES_METRICS.AT_LEAST_ONE_SEC_CHAMPION + " maturity points (0.25 extra points if there are more than 1)"
    asecTickets.innerHTML = " <strong>Percentage of ASEC tickets resolved:</strong> " + props.teamData.riskFactors?.asec_tickets_resolved + " out of " + ""+commonHelper.MAXIMUM_SCORES_METRICS.RESOLVED_ASEC_TICKETS + " maturity points"
    branchProtections.innerHTML = " <strong>Protected main/master branches:</strong> " + props.teamData.riskFactors?.branches_protected + " out of " + ""+commonHelper.MAXIMUM_SCORES_METRICS.BRANCHES_PROTECTED + " maturity points"
    totalScore.innerHTML = ""+scoreDescription
    moreInfo.innerHTML = "<br>Want to know more about how the score is calculated? Click <a target='_blank' href='https://docs.google.com/presentation/d/1xvhfk_AzIMUEFeCFbDV-4dPkB7_Oo0oD3qwY5gn8Z6M/edit#slide=id.g18b9fa1290e_0_37'>here</a>"
  }

  const addASECTiles = (arr: any[]) => {

    let openItems = 0;

    if (props.teamData?.asec?.projects?.length > 0) {
      props.teamData.asec.projects.forEach(project => {
        openItems = openItems + project.openItems;
        }
      )

      if(openItems > 0) {
        const tool = {
          tool: 'asec-open-tickets',
          rating: `${openItems} Security Design Recommendations`,
        }
        
        arr.push(tool)
      }
    }
  }

  const addSnykTiles = (arr: any[]) => {

    if (props.teamData.snyk && props.teamData.snyk.issues && props.teamData?.snyk?.issues?.highPriorityScoreFindings?.length > 0){
      const tool = {
        tool: 'snyk-high-priority-issues',
        rating: `${props.teamData.snyk.issues.highPriorityScoreFindings.length} high priority ${props.teamData.snyk.issues.highPriorityScoreFindings.length === 1 ? 'issue' : 'issues'}`,
      }
      
      arr.push(tool)
    }

    const snykPendingRepos = numberOfPendingReposInSnyk(props.teamData.semgrep);

    if(snykPendingRepos > 0) {
      const tool = {
        tool: 'snyk-repos-not-scanned',
        rating: `${snykPendingRepos} ${snykPendingRepos === 1 ? 'repository' : 'repositories'} not being scanned`,
      }
      
      arr.push(tool)
    }
  }

  const addSemgrepTiles = (arr: any[]) => {
    if (props.teamData.semgrep && (props.teamData.semgrep.list_private_and_internal_repos_without_SemGrep?.length > 0 ||
      props.teamData.semgrep.list_public_repos_without_SemGrep?.length > 0)) {

        const reposWithoutSemgrep = props.teamData.semgrep?.list_private_and_internal_repos_without_SemGrep?.length +
                                    props.teamData.semgrep?.list_public_repos_without_SemGrep?.length;

        const tool = {
          tool: 'semgrep-repos-not-scanned',
          rating: `${reposWithoutSemgrep} ${reposWithoutSemgrep === 1 ? 'repository' : 'repositories'} not being scanned`,
        }
        
        arr.push(tool)

    }

    if(props.teamData.semgrep?.high_vulnerabilities > 0) {
      const tool = {
        tool: 'semgrep-high-vulnerabilities',
        rating: `${props.teamData.semgrep.high_vulnerabilities} ${props.teamData.semgrep.high_vulnerabilities === 1 ? ' high vulnerability' : 'high vulnerabilities'}`,
      }
      
      arr.push(tool)
    }

   }


  const addGithubTiles = (arr: any[]) => {

    if (props.teamData.semgrep) {    

      const unprotedtedBranches = commonHelper.getReposWithUnprotectedBranches(props.teamData.semgrep);

      if(unprotedtedBranches.length > 0) {
        const tool = {
          tool: 'github-branch-protections',
          rating: `${unprotedtedBranches.length} ${unprotedtedBranches.length === 1 ? 'main/master branch missing some protections' : 'main/master branches missing some protections'}`,
        }
        
        arr.push(tool)
      }
    }
  }


  const numberOfPendingReposInSnyk = (semgrep: any) => {

    let counter: number = 0;


    for(let i=0; i<semgrep?.list_private_and_internal_repos_without_SemGrep?.length; i++) {
      if(semgrep.list_private_and_internal_repos_without_SemGrep[i]?.includes(commonHelper.PENDING_TO_BE_SCANNED_REPO_FLAG)) {
        counter++;
      }
    }

    for(let i=0; i<semgrep?.list_private_and_internal_repos_with_SemGrep?.length; i++) {
      if(semgrep.list_private_and_internal_repos_with_SemGrep[i]?.includes(commonHelper.PENDING_TO_BE_SCANNED_REPO_FLAG)) {
        counter++;
      }
    }

    for(let i=0; i<semgrep?.list_public_repos_without_SemGrep?.length; i++) {
      if(semgrep.list_public_repos_without_SemGrep[i]?.includes(commonHelper.PENDING_TO_BE_SCANNED_REPO_FLAG)) {
        counter++;
      }
    }

    for(let i=0; i<semgrep?.list_public_repos_with_SemGrep?.length; i++) {
      if(semgrep.list_public_repos_with_SemGrep[i]?.includes(commonHelper.PENDING_TO_BE_SCANNED_REPO_FLAG)) {
        counter++;
      }
    }

    return counter;
  }

  const getStatusEmoji = (metricValue: number, metricMaxScore: number, metricName: string) => { 

    // Adding this because having more than one SecChamp is optional
    if(metricName === "other_lack_of_sec_champs" && metricValue >= 1) {
      return "&#11088";
    }

    if(metricValue === 0) {
      return "&#128308";
    }

    else if(metricValue === metricMaxScore) {
      return "&#11088";
    }

    else if(metricValue < metricMaxScore*0.5) {
      return "&#128993";
    }

    else {
      return "&#128994";
    }

  }

  const fillStatusEmoji = (metrics: any) => {

    const openSECTickets_status = document.getElementById('openSECTickets_status',) as HTMLDivElement | null;
    const openSECTicketsSLA_status = document.getElementById('openSECTicketsSLA_status',) as HTMLDivElement | null;
    const isUsingSemgrep_status = document.getElementById('isUsingSemgrep_status',) as HTMLDivElement | null;
    const highVulnsSemgrep_status = document.getElementById('highVulnsSemgrep_status',) as HTMLDivElement | null;
    const isUsingSnyk_status = document.getElementById('isUsingSnyk_status',) as HTMLDivElement | null;
    const highVulnsInSnyk_status = document.getElementById('highVulnsInSnyk_status',) as HTMLDivElement | null;
    const hasSecChamp_status = document.getElementById('hasSecChamp_status',) as HTMLDivElement | null;
    const asecTickets_status = document.getElementById('asecTickets_status',) as HTMLDivElement | null;
    const branchProtections_status = document.getElementById('branchProtections_status',) as HTMLDivElement | null;

    const maxScore = {
      snyk_org_not_found: commonHelper.MAXIMUM_SCORES_METRICS.USING_SNYK,
      snyk_max_priority_score_reached: commonHelper.MAXIMUM_SCORES_METRICS.NO_HIGH_PRIORITY_ISSUES_SNYK,
      jira_open_issues: commonHelper.MAXIMUM_SCORES_METRICS.OPEN_SEC_TICKETS,
      jira_open_issues_breached_sla: commonHelper.MAXIMUM_SCORES_METRICS.OPEN_SEC_TICKETS_PAST_SLA,
      other_lack_of_sec_champs: commonHelper.MAXIMUM_SCORES_METRICS.AT_LEAST_ONE_SEC_CHAMPION,
      is_using_semgrep_on_some_repos: commonHelper.MAXIMUM_SCORES_METRICS.USING_SEMGREP,
      high_vulnerabilities_in_semgrep: commonHelper.MAXIMUM_SCORES_METRICS.NO_HIGH_VULN_SEMGREP,
      asec_tickets_resolved: commonHelper.MAXIMUM_SCORES_METRICS.RESOLVED_ASEC_TICKETS,
      branches_protected: commonHelper.MAXIMUM_SCORES_METRICS.BRANCHES_PROTECTED
    };

    for (var metricName in metrics) {

      const metricValue = metrics[metricName];
      const emoji = getStatusEmoji(metricValue, maxScore[metricName], metricName);

      if(metricName === "snyk_org_not_found") {
        isUsingSnyk_status.innerHTML = emoji;
      }

      else if(metricName === "snyk_max_priority_score_reached") {
        highVulnsInSnyk_status.innerHTML = emoji;
      }

      else if(metricName === "jira_open_issues") {
        openSECTickets_status.innerHTML = emoji;
      }

      else if(metricName === "jira_open_issues_breached_sla") {
        openSECTicketsSLA_status.innerHTML = emoji;
      }

      else if(metricName === "other_lack_of_sec_champs") {
        hasSecChamp_status.innerHTML = emoji;
      }

      else if(metricName === "is_using_semgrep_on_some_repos") {
        isUsingSemgrep_status.innerHTML = emoji;
      }

      else if(metricName === "high_vulnerabilities_in_semgrep") {
        highVulnsSemgrep_status.innerHTML = emoji;
      }

      else if(metricName === "asec_tickets_resolved") {
        asecTickets_status.innerHTML = emoji;
      }

      else if(metricName === "branches_protected") {
        branchProtections_status.innerHTML = emoji;
      }

    }

  }

  const getScoreScription = (redAlertSituation: boolean, redAlertsScore: number, maturityPointsTotal: number) => {

    if(redAlertSituation && redAlertsScore !== 0) {
      // maturityPointsTotal is the final score that comes from the backend. Red alert score is already
      // being considered. We do this show the user how the final score is calculated.
      return parseFloat(maturityPointsTotal.toFixed(2)) + " - " + parseFloat(redAlertsScore.toFixed(2)) + " = " + (maturityPointsTotal-redAlertsScore).toFixed(2);
    }

    else {
      return maturityPointsTotal.toFixed(2);
    }

  }
  const fillRedAlertMetricsInfo = (props: any) => {
    if(props.teamData.redAlerts.more_than_1_critical_sec_ticket.active) {
      const RA_more_than_1_critical_sec_ticket = document.getElementById('RA_more_than_1_critical_sec_ticket',) as HTMLDivElement | null;
      RA_more_than_1_critical_sec_ticket.innerHTML = " <strong>More than 1 critical open SEC ticket:</strong> -" + props.teamData.redAlerts.more_than_1_critical_sec_ticket.decreasedPoints + " maturity points"
    }

    if(props.teamData.redAlerts.more_than_3_high_sec_ticket.active) {
      const RA_more_than_3_high_sec_ticket = document.getElementById('RA_more_than_3_high_sec_ticket',) as HTMLDivElement | null;
      RA_more_than_3_high_sec_ticket.innerHTML = " <strong>More than 3 high open SEC tickets:</strong> -" + props.teamData.redAlerts.more_than_3_high_sec_ticket.decreasedPoints + " maturity points"
    }

    if(props.teamData.redAlerts.more_than_1_critical_sec_ticket_past_sla.active) {
      const RA_more_than_1_critical_sec_ticket_past_sla = document.getElementById('RA_more_than_1_critical_sec_ticket_past_sla',) as HTMLDivElement | null;
      RA_more_than_1_critical_sec_ticket_past_sla.innerHTML = " <strong>More than 1 critical open SEC tickets past SLA:</strong> -" + props.teamData.redAlerts.more_than_1_critical_sec_ticket_past_sla.decreasedPoints + " maturity points"
    }

    if(props.teamData.redAlerts.more_than_2_sec_tickets_past_sla.active) {
      const RA_more_than_2_sec_tickets_past_sla = document.getElementById('RA_more_than_2_sec_tickets_past_sla',) as HTMLDivElement | null;
      RA_more_than_2_sec_tickets_past_sla.innerHTML = " <strong>More than 2 high open SEC tickets past SLA:</strong> -" + props.teamData.redAlerts.more_than_2_sec_tickets_past_sla.decreasedPoints + " maturity points"
    }

    if(props.teamData.redAlerts.more_than_6_issues_with_priorityScore_higher_than_770_snyk.active) {
      const RA_more_than_6_issues_with_priorityScore_higher_than_770_snyk = document.getElementById('RA_more_than_6_issues_with_priorityScore_higher_than_770_snyk',) as HTMLDivElement | null;
      RA_more_than_6_issues_with_priorityScore_higher_than_770_snyk.innerHTML = " <strong>More than 40 high vulnerabilities with Priority Score higher than 770 in Snyk:</strong> -" + props.teamData.redAlerts.more_than_6_issues_with_priorityScore_higher_than_770_snyk.decreasedPoints + " maturity points"
    }

    if(props.teamData.redAlerts.more_than_6_high_vulnerabilities_semgrep.active) {
      const RA_more_than_6_high_vulnerabilities_semgrep = document.getElementById('RA_more_than_6_high_vulnerabilities_semgrep',) as HTMLDivElement | null;
      RA_more_than_6_high_vulnerabilities_semgrep.innerHTML = " <strong>More than 6 high vulnerabilities in Semgrep:</strong> -" + props.teamData.redAlerts.more_than_6_high_vulnerabilities_semgrep.decreasedPoints + " maturity points"
    }
  }

  const getMaturityPoints = (teamData: any) => {

      return  teamData.riskFactors?.asec_tickets_resolved +
              teamData.riskFactors?.high_vulnerabilities_in_semgrep +
              teamData.riskFactors?.is_using_semgrep_on_some_repos +
              teamData.riskFactors?.jira_open_issues +
              teamData.riskFactors?.jira_open_issues_breached_sla +
              teamData.riskFactors?.other_lack_of_sec_champs + 
              teamData.riskFactors?.snyk_max_priority_score_reached +
              teamData.riskFactors?.snyk_org_not_found +
              teamData.riskFactors?.branches_protected;

  }

  const getRedAlertsScore = (teamData: any) => {
    if(redAlertSituation == true) {
      return  +teamData.redAlerts.more_than_1_critical_sec_ticket.decreasedPoints +
              +teamData.redAlerts.more_than_3_high_sec_ticket.decreasedPoints +
              +teamData.redAlerts.more_than_1_critical_sec_ticket_past_sla.decreasedPoints +
              +teamData.redAlerts.more_than_2_sec_tickets_past_sla.decreasedPoints +
              +teamData.redAlerts.more_than_6_issues_with_priorityScore_higher_than_770_snyk.decreasedPoints +
              +teamData.redAlerts.more_than_6_high_vulnerabilities_semgrep.decreasedPoints;
    }

    else {
      return 0;
    }
  }
  const checkRedAlertMetrics = (redAlerts: any) => {

     setRedAlertSituation(redAlerts.more_than_1_critical_sec_ticket.active == true ||
                          redAlerts.more_than_3_high_sec_ticket.active == true ||
                          redAlerts.more_than_1_critical_sec_ticket_past_sla.active == true ||
                          redAlerts.more_than_2_sec_tickets_past_sla.active == true ||
                          redAlerts.more_than_6_issues_with_priorityScore_higher_than_770_snyk.active == true ||
                          redAlerts.more_than_6_high_vulnerabilities_semgrep.active == true);
  }

  const getRiskLabel = (risk: any): JSX.Element => {
    const map = {
      high: {
        color: 'error',
        text: 'HIGH',
      },
      red_alert: {
        color: 'error',
        text: 'RED ALERT',
      },
      medium: {
        color: 'warning',
        text: 'MEDIUM',
      },
      low: {
        color: 'primary',
        text: 'LOW',
      },
      none: {
        color: 'success',
        text: 'none',
      },
    };

    const { text, color }: any = map[risk];

    return <Label color={color}>{text}</Label>;
  };



  return (
    <>

    <Grid
      container
      spacing={1}
    >
    <Grid
      item
      xs={7}
    >
      
      { isOpen ? <Alert onClose={() => setAlertOpen(false)} sx={{ marginLeft: 3, marginRight: 3 }} variant="outlined" severity="info"> 
          The team's maturity score is distributed as follows:
          <br></br>

          <br></br><div><strong><u>Maturity metrics</u></strong></div>
          <br></br><div style={{display: 'inline'}} id="openSECTickets_status"></div><div style={{display: 'inline'}} id="openSECTickets"></div>
          <br></br><div style={{display: 'inline'}} id="openSECTicketsSLA_status"></div><div style={{display: 'inline'}} id="openSECTicketsSLA"></div>
          <br></br><div style={{display: 'inline'}} id="isUsingSemgrep_status"></div><div style={{display: 'inline'}} id="isUsingSemgrep"></div>
          <br></br><div style={{display: 'inline'}} id="highVulnsSemgrep_status"></div><div style={{display: 'inline'}} id="highVulnsSemgrep"></div>
          <br></br><div style={{display: 'inline'}} id="isUsingSnyk_status"></div><div style={{display: 'inline'}} id="isUsingSnyk"></div>
          <br></br><div style={{display: 'inline'}} id="highVulnsInSnyk_status"></div><div style={{display: 'inline'}} id="highVulnsInSnyk"></div>
          <br></br><div style={{display: 'inline'}} id="hasSecChamp_status"></div><div style={{display: 'inline'}} id="hasSecChamp"></div>
          <br></br><div style={{display: 'inline'}} id="asecTickets_status"></div><div style={{display: 'inline'}} id="asecTickets"></div>
          <br></br><div style={{display: 'inline'}} id="branchProtections_status"></div><div style={{display: 'inline'}} id="branchProtections"></div>

          <br></br><br></br><div><strong><u>Emoji status meaning</u></strong></div>
          <br></br><div style={{display: 'inline'}}>⭐</div><div style={{display: 'inline'}}> Maximum score</div>
          <br></br><div style={{display: 'inline'}}>🟢</div><div style={{display: 'inline'}}> Close to reach the maximum score</div>
          <br></br><div style={{display: 'inline'}}>🟡</div><div style={{display: 'inline'}}> Can be improved</div>
          <br></br><div style={{display: 'inline'}}>🔴</div><div style={{display: 'inline'}}> Lowest score</div>

          { redAlertSituation && (<>

          <br></br><br></br><div><strong><u>Red Alert metrics</u></strong></div>
          { props.teamData.redAlerts.more_than_1_critical_sec_ticket.active && (<> <br></br><div style={{display: 'inline'}} id="RA_more_than_1_critical_sec_ticket"></div> </>)}
          { props.teamData.redAlerts.more_than_3_high_sec_ticket.active && (<> <br></br><div style={{display: 'inline'}} id="RA_more_than_3_high_sec_ticket"></div> </>)}
          { props.teamData.redAlerts.more_than_1_critical_sec_ticket_past_sla.active && (<> <br></br><div style={{display: 'inline'}} id="RA_more_than_1_critical_sec_ticket_past_sla"></div> </>)}
          { props.teamData.redAlerts.more_than_2_sec_tickets_past_sla.active && (<> <br></br><div style={{display: 'inline'}} id="RA_more_than_2_sec_tickets_past_sla"></div> </>)}
          { props.teamData.redAlerts.more_than_6_issues_with_priorityScore_higher_than_770_snyk.active && (<> <br></br><div style={{display: 'inline'}} id="RA_more_than_6_issues_with_priorityScore_higher_than_770_snyk"></div> </>)}
          { props.teamData.redAlerts.more_than_6_high_vulnerabilities_semgrep.active && (<> <br></br><div style={{display: 'inline'}} id="RA_more_than_6_high_vulnerabilities_semgrep"></div> </>)}

          </>)}

          <br></br><br></br><div><strong><u>Final score</u></strong></div><br></br>
          <div id="totalScore"></div>
          <div id="moreInfo"></div>
          </Alert> : null }

          <Tiles tileData={tileData} />
          <Grid
            item
        >

      <Box sx={{ p: 3, paddingTop: 1, paddingLeft: 3}}>
      {recommendations.map((obj) => (
          <Grid
                item
                sx={{ paddingBottom: 1, paddingTop: 1 }}
                key={obj.id}
          >
                  <Card>
                    <CardHeader
                      action={
                          getRiskLabel(obj.risk)
                      }
                      title={<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom> Recommendation </Typography>}
                      subheader={<Typography variant="h6" component="div"> {obj.description} </Typography>}
                    />
                    <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        {obj.urlText} <Link target="_blank" rel="noopener" href={obj.url}>here</Link>
                        </Typography>
                    </CardContent>
                  </Card>
            </Grid>
          ))}

      </Box>
</Grid>
    </Grid>
    <Grid item xs={5} >
          <RiskChart riskOverTime={riskOverTime} />
        </Grid>
      </Grid>

    </>
  );
};

export default TeamOverviewRecommendation;
