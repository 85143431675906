import { Auth0Context, Auth0Provider, Auth0ProviderOptions, GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { FC } from 'react';

const deferred = (() => {
  const props: { promise: Promise<(options?: GetTokenSilentlyOptions) => Promise<string>>; resolve?: any } = {} as any;

  props.promise = new Promise((resolve) => (props.resolve = resolve));

  return props;
})();

export const getTokenSilently = async (opts: GetTokenSilentlyOptions) => {
  const getToken = await deferred.promise;
  return getToken(opts);
};

const StaticAuth0Provider: FC<Auth0ProviderOptions> = (props: Auth0ProviderOptions) => {
  const { children, domain, clientId, authorizationParams } = props;
  return (
    <Auth0Provider clientId={clientId} domain={domain} authorizationParams={authorizationParams}>
      <Auth0Context.Consumer>
        {({
          getAccessTokenSilently,
        }: {
          getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
        }) => {
          deferred.resolve(getAccessTokenSilently);
          return <>{children}</>;
        }}
      </Auth0Context.Consumer>
    </Auth0Provider>
  );
};

export default StaticAuth0Provider;
