export const SNYK_SUPPORTED_MANIFEST_FILES = ["package.json","go.mod", "build.gradle", "requirements.txt"];
export const PROTECTED_BRANCHES = ["main", "master"];

// SNYK REPOSITORY FLAGS
export const SCANNED_REPO_FLAG = "|s1";
export const PENDING_TO_BE_SCANNED_REPO_FLAG = "|s2";
export const EXCLUDED_REPO_FLAG = "|s3";

// GITHUB REPOSITORY FLAGS
export const MAIN_BRANCH_UNPROTECTED = "|g1";
export const PULL_REVIEWS_NOT_REQUIRED = "|g2";
export const DISMISS_STALE_REVIEWS_FALSE = "|g3";
export const REQUIRE_CODE_OWNER_REVIEWS_FALSE = "|g4"
export const APPROVING_REVIEW_COUNT_ZERO = "|g5";
export const HAS_DISMISSAL_RESTRICTIONS = "|g6";
export const ENFORCE_ADMINS_FALSE = "|g7";
export const HAS_NO_RESTRICTIONS = "|g8";
export const ALLOW_FORCE_PUSHES_TRUE = "|g9";
export const ALLOW_DELETIONS_TRUE = "|g10";

// OPSLEVEL FLAGS
export const TIER_1_REPOSITORY = "|o1";
export const TIER_2_REPOSITORY = "|o2";
export const TIER_3_REPOSITORY = "|o3";
export const NULL_TIER_REPOSITORY = "|o0";


export const cleanRepoFlags = (repoName: string) => {


  return repoName.replace(SCANNED_REPO_FLAG, "")
                 .replace(PENDING_TO_BE_SCANNED_REPO_FLAG, "")
                 .replace(EXCLUDED_REPO_FLAG, "")
                 .replace(MAIN_BRANCH_UNPROTECTED, "")
                 .replace(PULL_REVIEWS_NOT_REQUIRED, "")
                 .replace(DISMISS_STALE_REVIEWS_FALSE, "")
                 .replace(REQUIRE_CODE_OWNER_REVIEWS_FALSE, "")
                 .replace(APPROVING_REVIEW_COUNT_ZERO, "")
                 .replace(HAS_DISMISSAL_RESTRICTIONS, "")
                 .replace(ENFORCE_ADMINS_FALSE, "")
                 .replace(HAS_NO_RESTRICTIONS, "")
                 .replace(ALLOW_FORCE_PUSHES_TRUE, "")
                 .replace(ALLOW_DELETIONS_TRUE, "")
                 .replace(TIER_1_REPOSITORY, "")
                 .replace(TIER_2_REPOSITORY, "")
                 .replace(TIER_3_REPOSITORY, "")
                 .replace(NULL_TIER_REPOSITORY, "");
};

export const MAXIMUM_SCORES_METRICS = {
  OPEN_SEC_TICKETS: 1.75,
  OPEN_SEC_TICKETS_PAST_SLA: 1.75,
  USING_SEMGREP: 1,
  NO_HIGH_VULN_SEMGREP: 1,
  USING_SNYK: 1,
  NO_HIGH_PRIORITY_ISSUES_SNYK: 1,
  AT_LEAST_ONE_SEC_CHAMPION: 1,
  RESOLVED_ASEC_TICKETS: 1,
  BRANCHES_PROTECTED: 0.5
}

export const getReposByFlag = (semgrepData: any, flag: string) => {

    let repos = [];
  
    semgrepData.list_private_and_internal_repos_without_SemGrep.forEach(repo => {
      if(repo.includes(flag)) {
        repos.push(repo);
      }
    });
  
    semgrepData.list_private_and_internal_repos_with_SemGrep.forEach(repo => {
      if(repo.includes(flag)) {
        repos.push(repo);
      }
    });
  
    semgrepData.list_public_repos_without_SemGrep.forEach(repo => {
      if(repo.includes(flag)) {
        repos.push(repo);
      }
    });
  
    semgrepData.list_public_repos_with_SemGrep.forEach(repo => {
      if(repo.includes(flag)) {
        repos.push(repo);
      }
    });
  
    return repos;
  
  }

 export const getReposWithUnprotectedBranches = (repositories: any) => {

    const branchProtectionIssueFlag = "|g";
    let unprotectedBranches: string[] = [];

    for(let i = 0; i < repositories?.list_private_and_internal_repos_without_SemGrep?.length; i++) {
      if(repositories?.list_private_and_internal_repos_without_SemGrep[i].includes(branchProtectionIssueFlag) &&
         (repositories?.list_private_and_internal_repos_without_SemGrep[i].includes(TIER_1_REPOSITORY) ||
         repositories?.list_private_and_internal_repos_without_SemGrep[i].includes(TIER_2_REPOSITORY))) {
          unprotectedBranches.push(repositories?.list_private_and_internal_repos_without_SemGrep[i]);
      }
    }

    for(let i = 0; i < repositories?.list_private_and_internal_repos_with_SemGrep?.length; i++) {
      if(repositories?.list_private_and_internal_repos_with_SemGrep[i].includes(branchProtectionIssueFlag) &&
         (repositories?.list_private_and_internal_repos_with_SemGrep[i].includes(TIER_1_REPOSITORY) ||
         repositories?.list_private_and_internal_repos_with_SemGrep[i].includes(TIER_2_REPOSITORY))) {
          unprotectedBranches.push(repositories?.list_private_and_internal_repos_with_SemGrep[i]);
      }
    }

    for(let i = 0; i < repositories?.list_public_repos_without_SemGrep?.length; i++) {
      if(repositories?.list_public_repos_without_SemGrep[i].includes(branchProtectionIssueFlag)) {
        unprotectedBranches.push(repositories?.list_public_repos_without_SemGrep[i]);
      }
    }

    for(let i = 0; i < repositories?.list_public_repos_with_SemGrep?.length; i++) {
      if(repositories?.list_public_repos_with_SemGrep[i].includes(branchProtectionIssueFlag)) {
        unprotectedBranches.push(repositories?.list_public_repos_with_SemGrep[i]);
      }
    }

    return unprotectedBranches;
  }