import { useCallback, useEffect, useState, useMemo } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import { TeamMetric } from '../../api/models';
import { getTeamMetric, getRiskScoreOverTime as getMaturityScoreOverTime } from '../../api/analyticsApi';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import { useTheme } from '@material-ui/core/styles';
import LoadingScreen from 'src/components/LoadingScreen';
import TeamOverviewRecommendation from 'src/components/dashboard/overview/TeamOverviewRecommendation';
import moment from 'moment';
import * as commonHelper from './../../components/common/helper';

const TeamOverview: FC<any> = (props: any) => {
  const theme = useTheme();
  const { settings } = useSettings();
  const [teamData, setTeamData] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [riskOverTime, setRiskOverTime] = useState([]);
  const { id } = useParams();

  let query = useQuery();

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const RECOMMENDATIONS_PRIORITY = {
    VERY_HIGH: 4,
    HIGH: 3,
    MEDIUM: 2,
    LOW: 1
  };

  const showRedAlertRecommendations = (teamData: any, arr: any) => {
    if(teamData.redAlerts) {

      if(teamData.redAlerts?.more_than_1_critical_sec_ticket?.active === true) {
        arr.push({
          id: '1',
          description:
          'RED ALERT: There is more than 1 critical open SEC ticket. ' + 
          'This red alert is causing the score to decrease by ' + teamData.redAlerts?.more_than_1_critical_sec_ticket?.decreasedPoints + ' points. ' +
          'Please review them and work on a fix as soon as possible.',            
          risk: 'red_alert',
          priority: RECOMMENDATIONS_PRIORITY.VERY_HIGH,
          url: teamData.jira.pastDueDateHighOrCritical.url.replace("AND%20due%20%3C%3D%20now()", "")
                                                          .replace('AND%20createdDate%20%3E%3D%20%222021%2F04%2F01%22', '')
                                                          .replace('AND%20%22CVSS%2BCE%5BNumber%5D%22%20%3E%3D%207', 'AND%20%22CVSS%2BCE%5BNumber%5D%22%20%3E%3D%209'),
          urlText: 'View the list of high/critical vulnerabilities in Jira',
        });
      }

      if(teamData.redAlerts?.more_than_3_high_sec_ticket?.active === true) {
        arr.push({
          id: '2',
          description:
            'RED ALERT: There are more than 3 high open SEC tickets. ' + 
            'This red alert is causing the score to decrease by ' + teamData.redAlerts?.more_than_3_high_sec_ticket?.decreasedPoints + ' points. ' +
            'Please review them and work on a fix as soon as possible.',
          risk: 'red_alert',
          priority: RECOMMENDATIONS_PRIORITY.VERY_HIGH,
          url: teamData.jira.pastDueDateHighOrCritical.url.replace("AND%20due%20%3C%3D%20now()", "")
                                                          .replace('AND%20createdDate%20%3E%3D%20%222021%2F04%2F01%22', '')
                                                          .replace('AND%20%22CVSS%2BCE%5BNumber%5D%22%20%3E%3D%207', 'AND%20"CVSS%2BCE%5BNumber%5D"%20>%3D%207%20AND%20"CVSS%2BCE%5BNumber%5D"%20<%209'),
          urlText: 'View the list of high vulnerabilities in Jira',
        });
      }

      if(teamData.redAlerts?.more_than_1_critical_sec_ticket_past_sla?.active === true) {
        arr.push({
          id: '3',
          description:
            'RED ALERT: There is more than 1 critical open SEC ticket that is past SLA. ' + 
            'This red alert is causing the score to decrease by ' + teamData.redAlerts?.more_than_1_critical_sec_ticket_past_sla?.decreasedPoints + ' points. ' +
            'Please review them and work on a fix as soon as possible.',
          risk: 'red_alert',
          priority: RECOMMENDATIONS_PRIORITY.VERY_HIGH,
          url: teamData.jira.pastDueDateHighOrCritical.url.replace('AND%20%22CVSS%2BCE%5BNumber%5D%22%20%3E%3D%207', 'AND%20%22CVSS%2BCE%5BNumber%5D%22%20%3E%3D%209'),
          urlText: 'View the list of high/critical vulnerabilities in Jira',
        });
      }

      if(teamData.redAlerts?.more_than_2_sec_tickets_past_sla?.active === true) {
        arr.push({
          id: '4',
          description:
            'RED ALERT: There are more than 2 high open SEC tickets that are past SLA. ' + 
            'This red alert is causing the score to decrease by ' + teamData.redAlerts?.more_than_2_sec_tickets_past_sla?.decreasedPoints + ' points. ' +
            'Please review them and work on a fix as soon as possible.',
          risk: 'red_alert',
          priority: RECOMMENDATIONS_PRIORITY.VERY_HIGH,
          url: teamData.jira.pastDueDateHighOrCritical.url.replace('AND%20%22CVSS%2BCE%5BNumber%5D%22%20%3E%3D%207', 'AND%20"CVSS%2BCE%5BNumber%5D"%20>%3D%207%20AND%20"CVSS%2BCE%5BNumber%5D"%20<%209'),
          urlText: 'View the list of high vulnerabilities in Jira',
        });
      }

      if(teamData.redAlerts?.more_than_6_issues_with_priorityScore_higher_than_770_snyk?.active === true) {
        arr.push({
          id: '6',
          description:
            'RED ALERT: There are more than 40 high vulnerabilities with a Priority Score higher than 770 in Snyk. ' + 
            'This red alert is causing the score to decrease by ' + teamData.redAlerts?.more_than_6_issues_with_priorityScore_higher_than_770_snyk?.decreasedPoints + ' points. ' +
            'Please review them and work on a fix as soon as possible.',
          risk: 'red_alert',
          priority: RECOMMENDATIONS_PRIORITY.VERY_HIGH,
          url: "/overview/"+teamData.team.id+"/snyk-high-priority-issues",
          urlText: 'View the Snyk information of this team',
        });
      }

      if(teamData.redAlerts?.more_than_6_high_vulnerabilities_semgrep?.active === true) {
        arr.push({
          id: '7',
          description:
            'RED ALERT: There are more than 6 high vulnerabilities in Semgrep. ' + 
            'This red alert is causing the score to decrease by ' + teamData.redAlerts?.more_than_6_high_vulnerabilities_semgrep?.decreasedPoints + ' points. ' +
            'Please review them and work on a fix as soon as possible.',
          risk: 'red_alert',
          priority: RECOMMENDATIONS_PRIORITY.VERY_HIGH,
          url: "/overview/"+teamData.team.id+"/semgrep-high-vulnerabilities",
          urlText: 'View the Semgrep information of this team',
        });
      }
    }
  }

  const showJiraRecommendations = (teamData: any, arr: any) => {
    if (teamData.jira) {
      if (teamData.jira.openTicketsWithPastSLABySecurityRisk.critical > 0 || teamData.jira.openTicketsWithPastSLABySecurityRisk.high > 0) {
        arr.push({
          id: '100',
          description:
            'Some of the high/critical SEC tickets are past due SLA, please review them and work on a fix or start the risk acceptance process',
          risk: 'high',
          priority: RECOMMENDATIONS_PRIORITY.HIGH,
          url: teamData.jira.pastDueDateHighOrCritical.url,
          urlText: 'View the list of issues past due SLA date in Jira',
        });
      }

      if (teamData.jira.openTicketsWithPastSLABySecurityRisk.medium > 0) {
        arr.push({
          id: '101',
          description:
            'Some of the medium SEC tickets are past due SLA, please review them and work on a fix or start the risk acceptance process',
          risk: 'high',
          priority: RECOMMENDATIONS_PRIORITY.HIGH,
          url: teamData.jira.pastDueDateMedium.url,
          urlText: 'View the list of issues past due SLA date in Jira',
        });
      }

      if(teamData.jira.openTicketsWithPastSLABySecurityRisk.redAlertSLA.active === true) {

          let redAlertSLASECTicketsTotal: number = teamData.jira.openTicketsWithPastSLABySecurityRisk.redAlertSLA.SECTickets.length;

          if(redAlertSLASECTicketsTotal > 0) {
            let recommendationDesc: string;
            let redAlertSLASECTickets: string = teamData.jira.openTicketsWithPastSLABySecurityRisk.redAlertSLA.SECTickets.join(", ");

            if(redAlertSLASECTicketsTotal === 1) {
              recommendationDesc = "There is 1 SEC ticket that is past SLA for too long: " + redAlertSLASECTickets + ". Please work on fixing it.";
            }

            else {            
              recommendationDesc = "There are " + redAlertSLASECTicketsTotal  + " SEC tickets that are past SLA for too long: " + redAlertSLASECTickets + ". Please work on fixing them.";
            }

            arr.push({
              id: '102',
              description: recommendationDesc,
              risk: 'high',
              priority: RECOMMENDATIONS_PRIORITY.HIGH,
              url: teamData.jira.openTickets.url,
              urlText: 'View open SEC tickets in Jira',
            });
          }
          
        }

        if (teamData.jira.openTickets.total) {
          arr.push({
            id: '103',
            description: 'There are some open SEC tickets that require attention',
            risk: 'medium',
            priority: RECOMMENDATIONS_PRIORITY.MEDIUM,
            url: teamData.jira.openTickets.url,
            urlText: 'View open SEC tickets in Jira',
          });
        }
      }
  }

  const showSnykRecommendations = (teamData: any, arr: any) => {

      if (!teamData.snyk && teamData.semgrep) {
        arr.push({
          id: '200',
          description: "We couldn't find the team's organization in Snyk, which means its dependencies are not actively scanned for vulnerabilities",
          risk: 'high',
          priority: RECOMMENDATIONS_PRIORITY.HIGH,
          url: 'https://auth0team.atlassian.net/servicedesk/customer/portal/3/group/162/create/1120',
          urlText: 'Request Snyk organization for your team through Security Portal',
        });
      }
      
      if (teamData.snyk && teamData.snyk.issues?.highPriorityScoreFindings?.length > 0) {

        const recDescription = teamData.snyk.issues?.highPriorityScoreFindings?.length === 1 ? 'There is ' + teamData.snyk.issues?.highPriorityScoreFindings?.length + ' High Priority issue in Snyk. Please work on fixing it.' :
                                                                                             'There are ' + teamData.snyk.issues?.highPriorityScoreFindings?.length + ' High Priority issues in Snyk. Please work on fixin them.' ;
        
        arr.push({
          id: '201',
          description: recDescription,
          risk: 'high',
          priority: RECOMMENDATIONS_PRIORITY.HIGH,
          url: '/overview/' + teamData.team.id + '/snyk-high-priority-issues',
          urlText: 'View the details of Snyk issues ',
        });
      }
  }

  const showSemgrepRecommendations = (teamData: any, arr: any) => {
    if (teamData.semgrep && (teamData.semgrep.list_private_and_internal_repos_without_SemGrep?.length > 0 ||
      teamData.semgrep.list_public_repos_without_SemGrep.length > 0)) {

        const publicRepos = teamData.semgrep?.list_public_repos_without_SemGrep?.length + teamData.semgrep?.list_public_repos_with_SemGrep?.length;
        const privateRepos = teamData.semgrep?.list_private_and_internal_repos_without_SemGrep?.length + teamData.semgrep?.list_private_and_internal_repos_with_SemGrep?.length;
        const noSemgrepPublic = publicRepos > 0 ? teamData.semgrep?.list_public_repos_without_SemGrep?.length : 0;
        const noSemgrepPrivate = privateRepos > 0 ? teamData.semgrep?.list_private_and_internal_repos_without_SemGrep?.length : 0;

        let recommendationDesc: string;

        if(privateRepos > 0 && noSemgrepPrivate > 0 && noSemgrepPublic === 0) {
          recommendationDesc = noSemgrepPrivate + " private repositories are not being scanned by Semgrep"
        }

        else if(privateRepos > 0 && noSemgrepPrivate > 0 && noSemgrepPublic > 0) {
          recommendationDesc = noSemgrepPrivate + " private repositories "
        }

        if(publicRepos > 0 && noSemgrepPublic > 0 && noSemgrepPrivate > 0) {
          recommendationDesc = recommendationDesc + "and " + noSemgrepPublic + " public repositories are not being scanned by Semgrep";
        }
        
        else if(publicRepos > 0 && noSemgrepPublic > 0 && noSemgrepPrivate === 0) {
          recommendationDesc = noSemgrepPublic + " the private repositories are not being scanned by Semgrep"
        }

        recommendationDesc = recommendationDesc + '. Please follow the Semgrep integration procedure for the missing repositories.'

        arr.push({
        id: '300',
        description: recommendationDesc,
        risk: 'high',
        priority: RECOMMENDATIONS_PRIORITY.HIGH,
        url: '/overview/' + teamData.team.id + '/semgrep-repos-not-scanned',
        urlText: 'View the details of the Semgrep integration status ',
        });
      }
    
    if(teamData.semgrep && teamData.semgrep.high_vulnerabilities > 0) {

      let desc: string = "";

      if(teamData.semgrep.high_vulnerabilities === 1) {
        desc = "There is one high vulnerability detected by Semgrep in one of the repositories owned by the team. Please review it and follow the triage process";
      }

      else {
        desc = "There are " + teamData.semgrep.high_vulnerabilities + " high vulnerabilities detected by Semgrep in at least one of the repositories owned by the team. Please review them and follow the triage process"
      }
      arr.push({
        id: '301',
        description: desc,
        risk: 'high',
        priority: RECOMMENDATIONS_PRIORITY.HIGH,
        url: '/overview/' + teamData.team.id + '/semgrep-high-vulnerabilities',
        urlText: 'View the list of repositories with high vulnerabilities detected by Semgrep ',
        });
    }
  }

  const showASECRecommendations = (teamData: any, arr: any) => {
    if (teamData.asec) {
        
      const hasOpenItems = teamData.asec?.projects?.filter(x => x.openItems > 0).length > 0;
  
      if(hasOpenItems) {

        let openItems = 0;
        
        teamData.asec?.projects?.forEach(project => {
          openItems = openItems + project.openItems;
        });

        arr.push({
          id: '501',
          description: 'There are ' + openItems + ' Security Design Recommendations pending to do. Please work on resolving them.',
          risk: 'medium',
          priority: RECOMMENDATIONS_PRIORITY.MEDIUM,
          url: '/overview/' + teamData.team.id + '/asec-open-tickets',
          urlText: 'View the list ',
        });
      }
    }
  }

  const showSecurityChampionsRecommendations = (teamData: any, arr: any) => {
    if (teamData.other.numberOfSecChamps === 0) {
      arr.push({
        id: '601',
        description: 'This team does not have any Security Champion. You might want to add at least one to bring in the security expertise.',
        risk: 'medium',
        priority: RECOMMENDATIONS_PRIORITY.MEDIUM,
        url: 'https://oktawiki.atlassian.net/wiki/spaces/ProdSec/pages/2536769235/Security+Champion+Program',
        urlText: 'Read on how to become a Security champion',
      });
    }
  }

  const showGithubRecommendations = (teamData: any, arr: any) => {

    const reposWithUnprotectedBranches = commonHelper.getReposWithUnprotectedBranches(teamData.semgrep);

    if (reposWithUnprotectedBranches?.length > 0) {

      const desc = reposWithUnprotectedBranches?.length === 1 ?
                   "There is one main/master branch that is missing at least one protection mechanism. Please work on configuring them." :
                   "There are " + reposWithUnprotectedBranches?.length + " main/master branches that are missing at least one protection mechanism. Please work on configuring them."
      arr.push({
        id: '701',
        description: desc,
        risk: 'medium',
        priority: RECOMMENDATIONS_PRIORITY.MEDIUM,
        url: '/overview/' + teamData.team.id + '/github-branch-protections',
        urlText: 'See the list of branches that are missing branch protections ',
      });
    }
  }

  const showSecurityBestPracticesRecommendations = (arr: any) => {
    arr.push({
      id: '1000',
      description: "This team is doing great! We don't have security recommendations at this time. 🥳",
      risk: 'none',
      priority: RECOMMENDATIONS_PRIORITY.LOW,
      url: 'https://cheatsheetseries.owasp.org/Glossary.html',
      urlText: 'Review the OWASP Cheat Sheets in the meantime',
    });
  }


  useEffect(() => {
    if (props.teamsData) {
    (async () => {
         try {
          let result: TeamMetric[] = [];
          let daysSubstract: number = 1;

          // Get today's metrics
          if(result === undefined || result.length === 0) {
            result = await getTeamMetric(id, moment().format('YYYY-MM-DD'));
          }

          while((result === undefined || result.length === 0) && daysSubstract < 7) {
            
            let parsedDate = moment().subtract(daysSubstract, 'days').format('YYYY-MM-DD')
            daysSubstract++;

            result = await getTeamMetric(id, parsedDate);
          }
           
          setTeamData(result);

         } catch (e) {
           console.error(e);
           toast.error('Error occurred while fetching new metrics');
         }
     })();
     }
  }, [props.teamsData]);

  useEffect(() => {
    if (props.teamsData) {
    (async () => {
         try {
            const dateQuery = query.get("date")
            const result = await getMaturityScoreOverTime(id, dateQuery);
            setRiskOverTime(result)
         } catch (e) {
           console.error(e);
           toast.error('Error occurred while fetching new metrics');
         }
     })();
     }
  }, [props.teamsData]);


  useEffect(() => {
    
    let recommendations = [];

    if(teamData) {

      showRedAlertRecommendations(teamData, recommendations);
      showJiraRecommendations(teamData, recommendations);
      showSnykRecommendations(teamData, recommendations);
      showSemgrepRecommendations(teamData, recommendations);
      showASECRecommendations(teamData, recommendations);
      showSecurityChampionsRecommendations(teamData, recommendations);
      showGithubRecommendations(teamData, recommendations);

      if (!recommendations || recommendations.length === 0) {
        showSecurityBestPracticesRecommendations(recommendations);
      }

      // Sort by priority in ascending order to show first the most important recommendations
      recommendations.sort((a: any, b: any) => (a.priority > b.priority) ? -1 : (b.priority > a.priority ? 1 : 0));

      setRecommendations(recommendations);
    }
 }, [teamData])

  return (
    <>
     <Toaster position="top-center" />
      <Helmet>
        <title>Security Compass | Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >

          <Container maxWidth={settings.compact ? 'xl' : false}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography color="textPrimary" variant="h5">
                {(teamData) ? `${teamData.team.name}` : 'Loading...'}
                </Typography>

                <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
                  <Link color="textPrimary" component={RouterLink} to="/" variant="subtitle2">
                    Dashboards
                  </Link>
                  <Link color="textPrimary" component={RouterLink} to="/overview" variant="subtitle2">
                    Overview
                  </Link>
                  <Typography color="textSecondary" variant="subtitle2">
                    {(teamData) ? `${teamData.team.name}` : 'Loading...'}
                  </Typography>

                </Breadcrumbs>
              </Grid>
            </Grid>
            <Box sx={{ mt: 3 }}>
            {teamData ? (
              <TeamOverviewRecommendation recommendations={recommendations} teamData={teamData} riskOverTime={riskOverTime} />
              ) : (
                <LoadingScreen></LoadingScreen>
              )}

              </Box>
          </Container>

      </Box>
    </>
  );
};

export default TeamOverview;
